import { useState, useEffect } from "react";

export const useResizeHandler = (addedHeight: number) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - addedHeight,
  });

  useEffect(() => {
    handleResize();
  }, [addedHeight]);

  const handleResize = () => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight - addedHeight,
    });
  };

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};
