import React, { Fragment } from "react";
import "./SuccessVerifiedEmail.css";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/buttons/ActionButtons";
import { ReactComponent as Checked } from "../../assets/Shape.svg";
import { TextField } from "@fluentui/react";
import { useDispatch } from "react-redux";
import { setIsGetStarted } from "../../store/reducers/userAuthentication";

interface SuccessVerifiedEmailProps {
  enteredEmail: string;
  setIsSuccessVerifiedEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuccessVerifiedEmail = (props: SuccessVerifiedEmailProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nextHandler = () => {
    dispatch(setIsGetStarted(true));
    navigate("/GetStarted");
  };

  return (
    <Fragment>
      <form>
        <TextField
          className="form-text-field"
          type="email"
          label="Email Address"
          disabled
          inputClassName="input-22"
          defaultValue={props.enteredEmail}
        />
        <div className="success-verified-email-text">
          <Checked />
          <span>Your email address is verified</span>
        </div>
      </form>
      <ActionButtons
        primarytext={"Continue"}
        hideSecondaryButton={true}
        onPrimaryButtonClickHandler={nextHandler}
      />
    </Fragment>
  );
};

export default SuccessVerifiedEmail;
