import { createSlice } from "@reduxjs/toolkit";
import { LaneDetail } from "../../pages/LaneDetails/LaneDetails";

export interface AccountInfo {
  companyName?: string;
  contactName?: string;
  emailAddress?: string;
  password?: string;
  mobileNumber?: string;
  role?: string;
  zip?: string;
  address?: string;
  city?: string;
  state?: string;
  sCACNumber?: string;
  dOTNumber?: string;
  insCarrier?: string;
  insPolicy?: string;
  insCoverage?: string;
  lanes?: LaneDetail[];
  truckCount?: number;
  trailerCount?: number;
  trailerTypes?: string[];
}

interface AccountInfoState {
  AccountInfo: AccountInfo;
}
const initialState = {
  AccountInfo: {},
} as AccountInfoState;

export const accountInfoReducer = createSlice({
  name: "AccountInfo",
  initialState,
  reducers: {
    addAccountInfo: (state, action) => {
      state.AccountInfo = action.payload;
    },
  },
});

export const { addAccountInfo } = accountInfoReducer.actions;
export default accountInfoReducer.reducer;
