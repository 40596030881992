import React, { Fragment, useState } from "react";
import "./LaneDetails.css";
import Lanes from "./Lane/Lanes";
import ActionButtons from "../../components/buttons/ActionButtons";
import {
  DefaultButton,
  IComboBoxOption,
  Icon,
  Link,
  PrimaryButton,
  Text,
  Toggle,
} from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { addAccountInfo } from "../../store/reducers/accountInfo";
import TermsAndConditionsView from "./TermsAndConditionsView";

interface LaneDetailsProps {
  setIsTermsAndConditionsAccepted: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  formErrormessages: LaneDetailErrormessage[];
  setFormErrormessages: React.Dispatch<
    React.SetStateAction<LaneDetailErrormessage[]>
  >;
  isTermsAndConditionsAccepted: boolean;
  lanes: LaneDetail[];
  setLanes: React.Dispatch<React.SetStateAction<LaneDetail[]>>;
  setPercentComplete: React.Dispatch<React.SetStateAction<number>>;
  setShowPage: React.Dispatch<React.SetStateAction<string>>;
}

export interface LaneDetail {
  fromCity: string;
  fromState: IComboBoxOption | undefined;
  toCity: string;
  toState: IComboBoxOption | undefined;
}

export interface LaneDetailErrormessage {
  fromCity: boolean;
  fromState: boolean;
  toCity: boolean;
  toState: boolean;
}

const LaneDetails = (props: LaneDetailsProps) => {
  const dispatch = useDispatch();
  const storAccountInfo = useSelector(
    (state: RootState) => state.accountInfo.AccountInfo
  );

  const [showTermsAndConditionsAccepted, setshowTermsAndConditionsAccepted] =
    useState<boolean>(false);

  const [
    isTermsAndConditionsAcceptedErrormessages,
    setisTermsAndConditionsAcceptedErrormessages,
  ] = useState<boolean>(false);

  const [isLanesDuplicate, setIsLanesDuplicate] = useState<boolean>(false);

  const nextHandler = () => {
    const findDuplicates = props.lanes
      .map(function (value) {
        return (
          value.fromCity.toLowerCase() +
          value.fromState?.key +
          value.toCity.toLowerCase() +
          value.toState?.key
        );
      })
      .some(function (value, index, array) {
        return array.indexOf(value) !== array.lastIndexOf(value);
      });

    if (findDuplicates) {
      setIsLanesDuplicate(true);
    } else {
      props.lanes.map((lane: LaneDetail, index: number) => {        
        if (lane.fromState?.text === "") {
          props.formErrormessages[index].fromState = true;
          props.setFormErrormessages([...props.formErrormessages]);
        }        
        if (lane.toState?.text === "") {
          props.formErrormessages[index].toState = true;
          props.setFormErrormessages([...props.formErrormessages]);
        }
      });

      const validFlag = props.formErrormessages.filter(
        (formErrormessage: LaneDetailErrormessage) => {
          return Object.values(formErrormessage).some((val) => val);
        }
      );

      if (validFlag.length === 0) {
        const laneDtailsInfo = {
          lanes: props.lanes,
        };
        if (props.isTermsAndConditionsAccepted) {
          dispatch(
            addAccountInfo({
              ...storAccountInfo,
              ...laneDtailsInfo,
            })
          );
          props.setShowPage("send");
          props.setPercentComplete(1);
        } else {
          setisTermsAndConditionsAcceptedErrormessages(true);
        }
      }
    }
  };

  const previousHandler = () => {
    props.setShowPage("fleet");
    props.setPercentComplete(0.6);
  };

  const addHandler = () => {
    const newLane: LaneDetail = {
      fromCity: "",
      fromState: { key: "", text: "" },
      toCity: "",
      toState: { key: "", text: "" },
    };

    const newformErrormessage = {
      fromCity: false,
      fromState: false,
      toCity: false,
      toState: false,
    };

    props.setLanes((prevLane) => [...prevLane, newLane]);
    props.setFormErrormessages((prevMessage) => [
      ...prevMessage,
      newformErrormessage,
    ]);
  };

  const onChangeToggle = () => {
    props.setIsTermsAndConditionsAccepted(!props.isTermsAndConditionsAccepted);
    setisTermsAndConditionsAcceptedErrormessages(false);
  };
  const handleClickOnLink = () => {
    setisTermsAndConditionsAcceptedErrormessages(false);
    setshowTermsAndConditionsAccepted(true);
  };

  const cleareDataHandler = () => {
    props.setLanes([
      {
        fromCity: "",
        fromState: { key: "", text: "" },
        toCity: "",
        toState: { key: "", text: "" },
      },
    ]);
    props.setFormErrormessages([
      { fromCity: false, fromState: false, toCity: false, toState: false },
    ]);
  };

  return (
    <Fragment>
      {!showTermsAndConditionsAccepted ? (
        <div className="business-details-middle-section">
          <h1>What are your lane preferences?</h1>

          <form className="business-details-light-gray-box lanes-light-gray-box">
            <div className="gray-box-header">
              <p>Lanes ({props.lanes.length})</p>
            </div>
            <div className="lane-details-body">
              {props.lanes.map((lane: LaneDetail, index: number) => (
                <Lanes
                  index={index}
                  setIsLanesDuplicate={setIsLanesDuplicate}
                  setFormErrormessages={props.setFormErrormessages}
                  formErrormessages={props.formErrormessages}
                  setLanes={props.setLanes}
                  lanes={props.lanes}
                />
              ))}
              <div className="lane-actions">
                <DefaultButton onClick={addHandler}>
                  <div className="lane-actions-btn">
                    <Icon iconName="CalculatorAddition" /> <p>Add</p>
                  </div>
                </DefaultButton>
                <PrimaryButton onClick={cleareDataHandler}>
                  <div className="lane-actions-btn">
                    <p>Clear</p>
                  </div>
                </PrimaryButton>
              </div>
            </div>
          </form>
          <div className=" business-details-footer lanes-details-footer">
            <div className="lanes-details-footer-toggle-box">
              <Toggle
                onChange={onChangeToggle}
                checked={props.isTermsAndConditionsAccepted}
              />
              <div className="lanes-details-footer-toggle-txt">
                <Text block={false}>
                  Accept our{" "}
                  <Link onClick={handleClickOnLink} underline>
                    terms and conditions
                  </Link>
                </Text>
              </div>
            </div>
            <ActionButtons
              primarytext={"Send application"}
              secondarytext={"Previous"}
              onPrimaryButtonClickHandler={nextHandler}
              onDefaultButtonClickHandler={previousHandler}
            />
          </div>
          {isTermsAndConditionsAcceptedErrormessages && (
            <span className="toggl-error-message">
              Please read and accept the terms and conditions to proceed with
              your application.
            </span>
          )}
          {isLanesDuplicate && (
            <span className="toggl-error-message">Lane already exists.</span>
          )}
        </div>
      ) : (
        <TermsAndConditionsView
          setshowTermsAndConditionsAccepted={setshowTermsAndConditionsAccepted}
        />
      )}
    </Fragment>
  );
};

export default LaneDetails;
