import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import UserGuides from "../../components/userGuides/UserGuides";
import apiClient from "../../api/http_client";
import "./AppConfirmation.css";
import LandingFooter from "../LandingPage/LandingFooter";
import Header from "../../components/header/Header";
import {
  DefaultButton,
  Icon,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { ReactComponent as FB } from "../../assets/FB.svg";
import { ReactComponent as TW } from "../../assets/TW.svg";

import { validEmail } from "../../utils/FormValidations";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import ActionButtons from "../../components/buttons/ActionButtons";
import { useResizeHandler } from "../../hooks/useResizeHandler";
import ProgressSteps from "../../components/ProgressSteps/ProgressSteps";
import { setIsGetStarted } from "../../store/reducers/userAuthentication";

const AppConfirmation = (props) => {
  const height = useResizeHandler(50).height;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storAccountInfo = useSelector(
    (state: RootState) => state.accountInfo.AccountInfo
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [applicationStatus, setApplicationStatus] = useState("No data found");
  const [linkValue, setLinkValue] = useState<string>(
    "https://freightnet.ai/"
  );
  const [emailErrormessage, setEmailErrormessage] = useState<string>("");

  useEffect(() => {
    apiClient
      .get(
        "/freightnet_application?emailAddress=" + storAccountInfo.emailAddress
      )
      .then((res: any) => {
        setApplicationStatus(res.data.status);
      })
      .catch((ex) => {
        console.log(ex.response.data);
      });
  }, []);

  const emailChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || validEmail(newValue) || newValue.length <= 50) {
        setEnteredEmail(newValue || "");
        setEmailErrormessage("");
        setShowSuccess(false);
      }
    },
    [props.enteredEmail]
  );

  const emailHandler = () => {
    if (validEmail(enteredEmail)) {
      setIsLoading(true);
      let data_structure = {
        contactName: storAccountInfo.contactName,
        emailAddress: storAccountInfo.emailAddress,
        friendEmail: enteredEmail,
      };

      apiClient
        .post("/friend_notification", data_structure)
        .then(() => {
          setEnteredEmail("");
          setIsLoading(false);
          setEmailErrormessage("");
          setShowSuccess(true);
        })
        .catch((e) => {
          setIsLoading(false);
          setEnteredEmail("");
          setEmailErrormessage("Error sending email.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setShowSuccess(false);
      setEmailErrormessage("Please enter a valid email address.");
    }
  };

  const getEmailErrorMessage = (value: string) => {
    if (value !== "") {
      !validEmail(value) &&
        setEmailErrormessage("Please enter a valid email address.");
      return "";
    }
  };

  const copiLinkHandler = () => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(linkValue);
    } else {
      document.execCommand("copy", true, linkValue);
    }
  };

  const getstatusIcon = (status: string) => {
    switch (status) {
      case "Pending":
        return <Icon iconName={"History"} />;
      case "Denied":
        return <Icon iconName={"StatusErrorFull"} />;

      case "Approved":
        return <Icon iconName={"CheckMark"} />;
    }
  };

  const getstatuscolor = (status: string) => {
    switch (status) {
      case "Pending":
        return "#a6a6a5";
      case "Denied":
        return "#CD2323";

      case "Approved":
        return "#37994B";
    }
  };

  const onStartoverClickHandler = () => {
    dispatch(setIsGetStarted(true));
    navigate("/GetStarted");
  };

  return (
    <Fragment>
      <div className="app-confirmation-container" style={{ height: height }}>
        <div className="app-confirmation-section">
          <Header
            title={"Congratulations, you’re in!"}
            showDescription={true}
            description="In the meantime, consider sharing with others in your network that could benefit from our program."
          />
          <div className="app-status-box">
            <h4>Your application status:</h4>
            {applicationStatus === "No data found" ? (
              <div className="app-Startover">
                <p>Almost there! Please complete your application.</p>
                <ActionButtons
                  primarytext={"Continue"}
                  hideSecondaryButton={true}
                  onPrimaryButtonClickHandler={onStartoverClickHandler}
                />
              </div>
            ) : (
              <ProgressSteps
                color={getstatuscolor(applicationStatus)}
                step={applicationStatus}
                element={getstatusIcon(applicationStatus)}
              />
            )}
          </div>

          <div className="app-confirmation-box">
            <h6>Invite your friends</h6>
            <div className="app-confirmation-email-box">
              <div className="app-confirmation-friends-left-box">
                <h4>Invite your friends by email</h4>
                <div className="app-confirmation-field-email">
                  <TextField
                    required
                    resizable={false}
                    className="form-text-field"
                    inputClassName="input-1"
                    placeholder="Enter email"
                    type="email"
                    label="Email Address"
                    onGetErrorMessage={getEmailErrorMessage}
                    errorMessage={emailErrormessage}
                    onChange={emailChangeHandler}
                    validateOnFocusOut
                    value={enteredEmail}
                    description={
                      showSuccess ? "Email has been sent. Send another?" : ""
                    }
                  />
                </div>

                <ActionButtons
                  primarytext={isLoading ? null : "Send invitations"}
                  element={
                    isLoading ? (
                      <Spinner
                        className="btn-spinner"
                        size={SpinnerSize.small}
                      />
                    ) : null
                  }
                  hideSecondaryButton={true}
                  onPrimaryButtonClickHandler={emailHandler}
                />
              </div>
              <div className="app-confirmation-friends-right-box">
                <h4>More ways to invite your friends</h4>
                <div className="app-confirmation-field-link">
                  <TextField
                    inputClassName="input-2"
                    className="link-field"
                    label="Copy link"
                    prefix="https://"
                    value={linkValue}
                  />
                  <DefaultButton
                    onClick={copiLinkHandler}
                    className="link-btn"
                    text="Copy link"
                  />
                </div>
                <div className="app-confirmation-media-btn">
                  <Link to="https://www.facebook.com/" target="_blank">
                    <DefaultButton className="facbook-btn">
                      <FB />
                      Share on Facebook
                    </DefaultButton>
                  </Link>
                  <Link
                    target="_blank"
                    to="https://twitter.com/i/flow/login?redirect_after_login=%2F%3Flang%3Den"
                  >
                    <DefaultButton className="Twitter-btn">
                      <TW /> Tweet on Twitter
                    </DefaultButton>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="app-confirmation-guides">
            <UserGuides />
          </div>
        </div>
        <LandingFooter />
      </div>
    </Fragment>
  );
};

export default AppConfirmation;
