import styled from "@emotion/styled";
import React from "react";

const StepWrapper = styled.div`
  z-index: 1;
  padding-top: 10px;
`;

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid ${(props) => props.color};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
`;

const StepCount = styled.div`
  font-size: 19px;
  text-align: center;
  margin-top: 2px;
  color: ${(props) => props.color};
`;

const StepsLabelContainer = styled.div``;

const StepLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

interface ProgressStepsProps {
  step: string;
  element: any;
  color: string | undefined;
}

const ProgressSteps = (props: ProgressStepsProps) => {
  return (
    <StepWrapper>
      <StepStyle color={props.color}>
        <StepCount color={props.color}>{props.element}</StepCount>
      </StepStyle>
      <StepsLabelContainer>
        <StepLabel color={props.color}>{props.step}</StepLabel>
      </StepsLabelContainer>
    </StepWrapper>
  );
};

export default ProgressSteps;
