import React, { Fragment } from "react";
import "./LandingFooter.css";
import { ReactComponent as Steps } from "../../assets/steps.svg";

interface UserFooterGuide {
  title: string;
  text: string;
}

const LandingPageFooter = () => {
  const UserFooterGuides: UserFooterGuide[] = [
    {
      title: "1",
      text: "Sign up and register for FREE by filling out our simple form with your business details.",
    },
    {
      title: "2",
      text: "Once submitted, we will validate your information.",
    },
    {
      title: "3",
      text: "We will let you know once you're approved! Celebrate, and start reaping the rewards of being a member.",
    },
  ];

  return (
    <Fragment>
      <div className="home-footer-container">
        <div className="home-footer-guides-header">
          <Steps />
          <div className="home-content-guides-text">
            <h4>How does it work?</h4>
            <span>
              Join our exclusive network of shippers in 3 simple steps.
            </span>
          </div>
        </div>
        <div className="home-footer-guides-section">
          {UserFooterGuides.map((item: UserFooterGuide) => {
            return (
              <div className="home-footer-guides-element">
                <div className="home-footer-circle-title">
                  <span>{item.title}</span>
                </div>
                <p>{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default LandingPageFooter;
