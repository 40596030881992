import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { loadTheme, ThemeProvider, getTheme } from "@fluentui/react";
import { store, persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";

loadTheme({
  palette: {
    themePrimary: "#0B2E51",
    themeLighterAlt: "#DEECFA",
    themeLighter: "#BCD9F6",
    themeLight: "#9BC6F1",
    themeTertiary: "#308EA1",
    themeSecondary: "white",
    themeDarkAlt: "#1864B1",
    themeDark: "#145494",
    themeDarker: "#09243F",
  },
  defaultFontStyle: { fontFamily: "trade-gothic-next, sans-serif" },
});
const PETheme = getTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));
initializeIcons();
root.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={PETheme} applyTo="body">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>
);
