import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import GetStarted from "./pages/GetStarted/GetStarted";
import Footer from "./components/Footer/Footer";
import { Container } from "./pages/Home/HomeEmotionStyledComponent";
import { useResizeHandler } from "./hooks/useResizeHandler";
import AppConfirmation from "./pages/AppConfirmation/AppConfirmation";
import NewAccount from "./pages/NewAccount/NewAccount";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import NotFound from "./components/notFound/NotFound";

function App() {
  const height = useResizeHandler(0).height;
  const responsiveHeight = useResizeHandler(0).height;
  const storAuthenticationInfo = useSelector(
    (state: RootState) => state.authenticationInfo
  );
  return (
    <>
      <Container height={height} responsiveHeight={responsiveHeight}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/CreateNewAccount" element={<NewAccount />} />
          <Route
            path="/GetStarted"
            element={
              storAuthenticationInfo.isGetStarted ? (
                <GetStarted />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/Home"
            element={
              storAuthenticationInfo.isAppConfirmation ? (
                <AppConfirmation />
              ) : (
                <NotFound />
              )
            }
          />
        </Routes>
        <Footer />
      </Container>
    </>
  );
}

export default App;
