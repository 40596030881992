import React, { Fragment, useState, useMemo } from "react";
import LandingPageBanner from "../LandingPage/LandingPageBanner";
import LandingFooter from "../LandingPage/LandingFooter";
import Logo from "../SideLogo/Logo";
import CarrierLogin from "../CarrierLogin/CarrierLogin";

import "./Home.css";

const Home = () => {
  const [showCarrierLogin, setCarrierLogin] = useState<boolean>(false);

  const carrierLogin = useMemo(
    () => <CarrierLogin setCarrierLogin={setCarrierLogin} />,
    [showCarrierLogin]
  );

  return (
    <Fragment>
      <div className="home-hero-section">
        {showCarrierLogin ? (
          carrierLogin
        ) : (
          <LandingPageBanner
            showCarrierLogin={showCarrierLogin}
            setCarrierLogin={setCarrierLogin}
          />
        )}
        <Logo />
      </div>
      {showCarrierLogin ? <></> : <LandingFooter />}
    </Fragment>
  );
};

export default Home;
