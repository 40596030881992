import React, { Fragment, useState } from "react";
import CreateNewAccount from "./CreateNewAccount";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../api/http_client";
import { useDispatch } from "react-redux";
import Logo from "../SideLogo/Logo";

interface NewAccountProps {}

const NewAccount = (props: NewAccountProps) => {
  const dispatch = useDispatch();
  const [isSuccessVerifiedEmail, setIsSuccessVerifiedEmail] =
    useState<boolean>(false);

  const [showVerifyEmail, setshowVerifyEmail] = useState<boolean>(false);
  const [enteredCompany, setEnteredCompany] = useState<string>("");
  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [enteredContact, setEnteredContact] = useState<string>("");
  const [enteredMobileNumber, setEnteredMobileNumber] = useState<string>("");
  const [enteredPassword, setEnteredPassword] = useState<string>("");
  const [enteredCode, setEnteredCode] = useState<string>("");
  const [codeErrormessage, setCodeErrormessage] = useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);

  const verifyEmailHandler = () => {
    setIsEmailVerified(true);
    apiClient
      .post("/registered_email", {
        companyName: enteredCompany,
        contactName: enteredContact,
        emailAddress: enteredEmail,
        password: enteredPassword,
        userEnteredCode: enteredCode,
        mobileNumber: enteredMobileNumber,
      })
      .then((res) => {
        setEnteredCode("");
        setIsEmailVerified(false);
        setIsSuccessVerifiedEmail(true);
      })
      .catch((e) => {
        console.log(e);
        setCodeErrormessage(true);
        toast.error("Please enter a valid verification code.", {
          closeButton: true,
          autoClose: 1000,
        });
        setEnteredCode("");
        setIsEmailVerified(false);
        setCodeErrormessage(true);
      });
  };

  const verifyEmailPreviousHandler = () => {
    setshowVerifyEmail(false);
    setEnteredCompany("");
    setEnteredEmail("");
    setEnteredContact("");
    setEnteredMobileNumber("");
    setEnteredPassword("");
  };

  return (
    <Fragment>
      <div className="home-hero-section">
        {showVerifyEmail ? (
          <VerifyEmail
            isEmailVerified={isEmailVerified}
            codeErrormessage={codeErrormessage}
            setCodeErrormessage={setCodeErrormessage}
            isSuccessVerifiedEmail={isSuccessVerifiedEmail}
            setIsSuccessVerifiedEmail={setIsSuccessVerifiedEmail}
            enteredEmail={enteredEmail}
            enteredCode={enteredCode}
            setEnteredEmail={setEnteredEmail}
            setEnteredCode={setEnteredCode}
            verifyEmailPreviousHandler={verifyEmailPreviousHandler}
            VerifyEmailHandler={verifyEmailHandler}
          />
        ) : (
          <CreateNewAccount
            enteredCompany={enteredCompany}
            enteredEmail={enteredEmail}
            enteredContact={enteredContact}
            enteredMobileNumber={enteredMobileNumber}
            enteredPassword={enteredPassword}
            setEnteredCompany={setEnteredCompany}
            setEnteredEmail={setEnteredEmail}
            setEnteredContact={setEnteredContact}
            setEnteredMobileNumber={setEnteredMobileNumber}
            setEnteredPassword={setEnteredPassword}
            setshowVerifyEmail={setshowVerifyEmail}
          />
        )}
        <Logo />
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default NewAccount;
