import { createSlice } from "@reduxjs/toolkit";

export interface authenticationInfo {
  isGetStarted: boolean;
  isAppConfirmation?: boolean;
}

const initialState = {
  isGetStarted: false,
  isAppConfirmation: false,
} as authenticationInfo;

export const authenticationInfoReducer = createSlice({
  name: "authenticationInfo",
  initialState,
  reducers: {
    setIsGetStarted: (state, action) => {
      state.isGetStarted = action.payload;
    },
    setIsAppConfirmation: (state, action) => {
      state.isAppConfirmation = action.payload;
    },
  },
});

export const { setIsAppConfirmation, setIsGetStarted } =
  authenticationInfoReducer.actions;
export default authenticationInfoReducer.reducer;
