import React, { useState, Fragment, useCallback } from "react";
import { ReactComponent as Building } from "../../assets/Building.svg";
import { ReactComponent as Person } from "../../assets/Person.svg";
import { ReactComponent as Mail } from "../../assets/Mail.svg";
import { ReactComponent as Call } from "../../assets/Call.svg";
import {
  TextField,
  ComboBox,
  IComboBox,
  IComboBoxOption,
} from "@fluentui/react";
import { useDispatch } from "react-redux";
import "./BusinessDetails.css";
import ActionButtons from "../../components/buttons/ActionButtons";
import { isValidPostalCode, UsAndCanadaStateOptions } from "../../utils/FormValidations";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { addAccountInfo } from "../../store/reducers/accountInfo";

interface BusinessDetailsProps {
  setPercentComplete: React.Dispatch<React.SetStateAction<number>>;
  setShowPage: React.Dispatch<React.SetStateAction<string>>;
  setEnteredRole: React.Dispatch<React.SetStateAction<string>>;
  setEnteredZip: React.Dispatch<React.SetStateAction<string>>;
  setEnteredAddress: React.Dispatch<React.SetStateAction<string>>;
  setEnteredCity: React.Dispatch<React.SetStateAction<string>>;
  setEnteredState: React.Dispatch<
    React.SetStateAction<IComboBoxOption | undefined>
  >;
  enteredRole: string;
  enteredZip: string;
  enteredAddress: string;
  enteredCity: string;
  enteredState: IComboBoxOption | undefined;
}

interface BusinessDetailsProps {
  setPercentComplete: React.Dispatch<React.SetStateAction<number>>;
  setShowPage: React.Dispatch<React.SetStateAction<string>>;
}

interface BusinessAcoountInfo {
  subTitle: string;
  title?: string;
  icon: JSX.Element;
}

const BusinessDetails = (props: BusinessDetailsProps) => {
  const dispatch = useDispatch();
  const storAccountInfo = useSelector(
    (state: RootState) => state.accountInfo.AccountInfo
  );

  const acoountInfoList: BusinessAcoountInfo[] = [
    {
      subTitle: "Company Name",
      title: storAccountInfo.companyName,
      icon: <Building />,
    },
    {
      subTitle: "Contact Name",
      title: storAccountInfo.contactName,
      icon: <Person />,
    },
    {
      subTitle: "Email",
      title: storAccountInfo.emailAddress,
      icon: <Mail />,
    },
    {
      subTitle: "Mobile number",
      title: storAccountInfo.mobileNumber,
      icon: <Call />,
    },
  ];

  const [addressErrormessage, setAddressErrormessage] =
    useState<boolean>(false);
  const [zipErrormessage, setZipErrormessage] = useState<boolean>(false);
  const [stateErrormessage, setStateErrormessage] = useState<boolean>(false);
  const [cityErrormessage, setCityErrormessage] = useState<boolean>(false);
  const [roleErrormessage, setRoleErrormessage] = useState<boolean>(false);

  const nextHandler = () => {
    if (props.enteredRole === "") {
      setRoleErrormessage(true);
    }
    if (props.enteredAddress === "") {
      setAddressErrormessage(true);
    }
    if (isValidPostalCode(props.enteredZip, "US") === false) {
      setZipErrormessage(true);
    }

    if (props.enteredState?.text === "") {
      setStateErrormessage(true);
    }
    if (props.enteredCity === "") {
      setCityErrormessage(true);
    }

    if (
      props.enteredRole !== "" &&
      props.enteredAddress !== "" &&
      isValidPostalCode(props.enteredZip, "US") !== false &&
      props.enteredState?.text !== "" &&
      props.enteredCity !== ""
    ) {
      dispatch(
        addAccountInfo({
          ...storAccountInfo,
          ...{
            role: props.enteredRole,
            zip: props.enteredZip,
            address: props.enteredAddress,
            city: props.enteredCity,
            state: props.enteredState?.text,
          },
        })
      );
      props.setShowPage("authority");
      props.setPercentComplete(0.4);
    }
  };

  const previousHandler = () => {
    props.setShowPage("welcome");
    props.setPercentComplete(0);
  };

  const cityChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredCity(newValue || "");
        setCityErrormessage(false);
      }
    },
    [props.enteredAddress]
  );

  const roleChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredRole(newValue || "");
        setRoleErrormessage(false);
      }
    },
    [props.enteredRole]
  );

  const addressChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredAddress(newValue || "");
        setAddressErrormessage(false);
      }
    },
    [props.enteredAddress]
  );

  const stateChangeHandler = useCallback(
    (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
      if (option) {
        props.setEnteredState(option);
        setStateErrormessage(false);
      }
    },
    [props.enteredState]
  );

  const zipChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredZip(newValue || "");
        setZipErrormessage(false);
      }
    },
    [props.enteredZip]
  );

  const getAdressErrorMessage = (value: string) => {
    if (value !== "") {
      value.length < 3 && setAddressErrormessage(true);
      return "";
    }
  };

  const getRoleErrorMessage = (value: string) => {
    if (value !== "") {
      value.length < 3 && setRoleErrormessage(true);
      return "";
    }
  };

  const getCityErrorMessage = (value: string) => {
    if (value !== "") {
      value.length < 2 && setCityErrormessage(true);
      return "";
    }
  };

  const getZipErrorMessage = (value: string) => {
    if (value !== "") {
      !isValidPostalCode(value, "US") && setZipErrormessage(true);
      return "";
    }
  };

  return (
    <Fragment>
      <div className="business-details-middle-section">
        <h1>Tell us about your business </h1>

        <form className="business-details-light-gray-box">
          <div className="gray-box-header">
            <p>Company information</p>
          </div>
          <div className="account-info-section">
            {acoountInfoList.map((info, index) => (
              <div className="account-info-box" key={index}>
                <div className="icon-label">{info.icon}</div>
                <div className="text-label">
                  <h2>{info.title}</h2>
                  <span>{info.subTitle}</span>
                </div>
              </div>
            ))}
          </div>
          <TextField
            required
            inputClassName="input-8"
            className="form-text-field"
            placeholder="Enter role"
            label="Role"
            errorMessage={roleErrormessage ? "Please enter a valid role." : ""}
            validateOnFocusOut
            onGetErrorMessage={getRoleErrorMessage}
            onChange={roleChangeHandler}
            value={props.enteredRole}
          />
          <TextField
            required
            inputClassName="input-9"
            className="form-text-field"
            placeholder="Enter address"
            label="Address"
            errorMessage={
              addressErrormessage ? "Please enter a valid address." : ""
            }
            validateOnFocusOut
            onGetErrorMessage={getAdressErrorMessage}
            onChange={addressChangeHandler}
            value={props.enteredAddress}
          />
          <div className="business-detail-adress-box">
            <TextField
              required
              inputClassName="input-10 city-text"
              placeholder="Enter city"
              className="city-field"
              label="City"
              onGetErrorMessage={getCityErrorMessage}
              errorMessage={
                cityErrormessage ? "Please enter a valid City." : ""
              }
              validateOnFocusOut
              value={props.enteredCity}
              onChange={cityChangeHandler}
            />
            <div className="business-detail-adress-small-box">
              <ComboBox
                required
                placeholder="Enter state"
                label="State"
                errorMessage={stateErrormessage ? "Please select a state." : ""}
                onChange={stateChangeHandler}
                options={UsAndCanadaStateOptions}
                selectedKey={props.enteredState?.key}
              />
              <TextField
                required
                placeholder="Enter ZIP"
                label="ZIP"
                inputClassName="input-11"
                onGetErrorMessage={getZipErrorMessage}
                errorMessage={
                  zipErrormessage ? "Please enter a valid zip code." : ""
                }
                validateOnFocusOut
                value={props.enteredZip}
                onChange={zipChangeHandler}
              />
            </div>
          </div>
        </form>
        <div className="business-details-footer">
          <ActionButtons
            primarytext={"Next"}
            secondarytext={"Previous"}
            onPrimaryButtonClickHandler={nextHandler}
            onDefaultButtonClickHandler={previousHandler}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default BusinessDetails;
