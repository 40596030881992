import React, { Fragment, useState } from "react";
import "./GetStarted.css";
import { useResizeHandler } from "../../hooks/useResizeHandler";
import { ReactComponent as FreightnetLogo } from "../../assets/freightnetlWhite.svg";
import { trailerType, trailerTypesList } from "../../resources/TrailerTypes";
import { IComboBoxOption, ProgressIndicator } from "@fluentui/react";
import WelcomeView from "../WelcomeView/WelcomeView";
import BusinessDetails from "../BusinessDetails/BusinessDetails";
import BusinessAuthority from "../BusinessAuthority/BusinessAuthority";
import FleetDetails from "../FleetDetails/FleetDetails";
import LaneDetails, {
  LaneDetail,
  LaneDetailErrormessage,
} from "../LaneDetails/LaneDetails";
import TransmitApp from "../TransmitApp/TransmitApp";
enum Page {
  WELCOME = "welcome",
  BUSINESS = "business",
  AUTHORITY = "authority",
  FLEET = "fleet",
  LANE = "lane",
  SEND = "send",
}
const GetStarted = (props) => {
  const height = useResizeHandler(50).height;
  const responsiveHeight = useResizeHandler(0).height;
  const [percentComplete, setPercentComplete] = useState<number>(0);
  const [showPage, setShowPage] = useState<string>(Page.WELCOME);

  //Business Details states
  const [enteredRole, setEnteredRole] = useState("");
  const [enteredZip, setEnteredZip] = useState("");
  const [enteredAddress, setEnteredAddress] = useState("");
  const [enteredCity, setEnteredCity] = useState("");
  const [enteredState, setEnteredState] = useState<IComboBoxOption | undefined>(
    undefined
  );

  //Business Authority states
  const [enteredDOTNumber, setEnteredDotNumber] = useState("");
  const [enteredSCAC, setEnteredSCAC] = useState("");
  const [enteredInsCarrier, setEnteredInsCarrier] = useState("");
  const [enteredInsPolicy, setEnteredInsPolicy] = useState("");
  const [enteredInsCoverage, setEnteredInsCoverage] = useState("");

  //Fleet Details
  const [trailerTypes, setTrailerTypes] = useState<any>(trailerTypesList);
  const [enteredTruckCount, setEnteredTruckCount] = useState<number>(0);
  const [enteredTrailerCount, setEnteredTrailerCount] = useState<number>(0);

  //Lanes details
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState<boolean>(false);
  const [lanes, setLanes] = useState<LaneDetail[]>([
    {
      fromCity: "",
      fromState: { key: "", text: "" },
      toCity: "",
      toState: { key: "", text: "" },
    },
  ]);
  const [formErrormessages, setFormErrormessages] = useState<
    LaneDetailErrormessage[]
  >([
    {
      fromCity: false,
      fromState: false,
      toCity: false,
      toState: false,
    },
  ]);

  return (
    <Fragment>
      <div
        className="get-started-container"
        style={{ height: window.innerWidth > 640 ? height : responsiveHeight }}
      >
        <div className="get-started-logo">
          <FreightnetLogo />
        </div>

        <div className="get-started-middle-container">
          {showPage === Page.WELCOME && (
            <WelcomeView
              setShowPage={setShowPage}
              setPercentComplete={setPercentComplete}
            />
          )}
          {showPage === Page.BUSINESS && (
            <BusinessDetails
              enteredRole={enteredRole}
              enteredZip={enteredZip}
              enteredAddress={enteredAddress}
              enteredCity={enteredCity}
              enteredState={enteredState}
              setEnteredRole={setEnteredRole}
              setEnteredZip={setEnteredZip}
              setEnteredAddress={setEnteredAddress}
              setEnteredCity={setEnteredCity}
              setEnteredState={setEnteredState}
              setShowPage={setShowPage}
              setPercentComplete={setPercentComplete}
            />
          )}
          {showPage === Page.AUTHORITY && (
            <BusinessAuthority
              setEnteredInsCoverage={setEnteredInsCoverage}
              setEnteredInsPolicy={setEnteredInsPolicy}
              setEnteredInsCarrier={setEnteredInsCarrier}
              setEnteredSCAC={setEnteredSCAC}
              setEnteredDotNumber={setEnteredDotNumber}
              enteredInsCoverage={enteredInsCoverage}
              enteredInsPolicy={enteredInsPolicy}
              enteredInsCarrier={enteredInsCarrier}
              enteredSCAC={enteredSCAC}
              enteredDOTNumber={enteredDOTNumber}
              setShowPage={setShowPage}
              setPercentComplete={setPercentComplete}
            />
          )}
          {showPage === Page.FLEET && (
            <FleetDetails
              trailerTypes={trailerTypes}
              enteredTruckCount={enteredTruckCount}
              enteredTrailerCount={enteredTrailerCount}
              setTrailerTypes={setTrailerTypes}
              setEnteredTruckCount={setEnteredTruckCount}
              setEnteredTrailerCount={setEnteredTrailerCount}
              setShowPage={setShowPage}
              setPercentComplete={setPercentComplete}
            />
          )}
          {showPage === Page.LANE && (
            <LaneDetails
              lanes={lanes}
              setLanes={setLanes}
              formErrormessages={formErrormessages}
              setFormErrormessages={setFormErrormessages}
              isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
              setIsTermsAndConditionsAccepted={setIsTermsAndConditionsAccepted}
              setShowPage={setShowPage}
              setPercentComplete={setPercentComplete}
            />
          )}
          {showPage === Page.SEND && (
            <TransmitApp
              setShowPage={setShowPage}
              setPercentComplete={setPercentComplete}
            />
          )}

          {showPage !== Page.SEND && (
            <div className="get-started-progress-bar">
              <span>{percentComplete * 100 + "%"}</span>
              <ProgressIndicator
                percentComplete={percentComplete}
                barHeight={8}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default GetStarted;
