import React, { Fragment } from "react";
import "./Header.css";
import { ReactComponent as FreightnetLogoSvg } from "../../assets/freightnetLogo.svg";

interface HeaderProps {
  title: string;
  hidLogo?: boolean;
  description?: string;
  showDescription: boolean;
}
const Header = (props: HeaderProps) => {
  return (
    <Fragment>
      <header>
        {props.hidLogo && <FreightnetLogoSvg />}
        <h1>{props.title}</h1>
        {props.showDescription && <p>{props.description}</p>}
      </header>
    </Fragment>
  );
};

export default Header;
