import React, { Fragment } from "react";
import "./Logo.css";
import HomeImg from "../../assets/home-img.png";
import PCSLogo from "../../assets/img-right.png";
import { useResizeHandler } from "../../hooks/useResizeHandler";

const Logo = () => {
  return (
    <Fragment>
      <div className="home-logo-container">
        <div className="home-img">
          <img src={HomeImg} />
        </div>
        <div className="home-logo">
          <img src={PCSLogo} />
        </div>
      </div>
    </Fragment>
  );
};

export default Logo;
