import React, { Fragment, useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationSuccessData from "../../resources/lottie-annimation/lf30_jllnuevk.json";
import { ReactComponent as DismissCircle } from "../../assets/Dismiss-Circle.svg";
import animationFaildData from "../../resources/lottie-annimation/lf30_ns1jrplu.json";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import apiClient from "../../api/http_client";
import "./TransmitApp.css";
import { ProgressIndicator, Spinner, SpinnerSize } from "@fluentui/react";
import ActionButtons from "../../components/buttons/ActionButtons";
import { useNavigate } from "react-router";
import { AccountInfo } from "../../store/reducers/accountInfo";
import {
  setIsAppConfirmation,
  setIsGetStarted,
} from "../../store/reducers/userAuthentication";

interface TransmitAppProps {
  setPercentComplete: React.Dispatch<React.SetStateAction<number>>;
  setShowPage: React.Dispatch<React.SetStateAction<string>>;
}

const TransmitApp = (props: TransmitAppProps) => {
  const navigate = useNavigate();
  const storAccountInfo = useSelector(
    (state: RootState) => state.accountInfo.AccountInfo
  );
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [transmitAppError, setTransmitAppError] = useState(
    "We are not able to send your application at this time. Please try again."
  );
  const [showFaild, setshowFaild] = useState(false);
  const [percentComplete, setPercentComplete] = React.useState(0);
  const [isPercentComplete, setIsPercentComplete] = React.useState(false);
  if (percentComplete === 1) {
    setIsPercentComplete(true);
  }

  const intervalDelay = 70;
  const intervalIncrement = 0.01;

  const SuccessOptions = {
    loop: true,
    autoplay: true,
    animationData: animationSuccessData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const faildOptions = {
    loop: true,
    autoplay: true,
    animationData: animationFaildData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (!isPercentComplete) {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  useEffect(() => {
    //build the data structure
    const request: AccountInfo = {
      companyName: storAccountInfo.companyName,
      contactName: storAccountInfo.contactName,
      emailAddress: storAccountInfo.emailAddress,
      mobileNumber: storAccountInfo.mobileNumber,
      address: storAccountInfo.address,
      city: storAccountInfo.city,
      role: storAccountInfo.role,
      state: storAccountInfo.state,
      zip: storAccountInfo.zip,
      sCACNumber: storAccountInfo.sCACNumber,
      dOTNumber: storAccountInfo.dOTNumber,
      insCarrier: storAccountInfo.insCarrier,
      insPolicy: storAccountInfo.insPolicy,
      insCoverage: storAccountInfo.insCoverage,
      lanes: storAccountInfo.lanes,
      truckCount: storAccountInfo.truckCount,
      trailerCount: storAccountInfo.trailerCount,
      trailerTypes: storAccountInfo.trailerTypes,
    };

    apiClient
      .post("/freightnet_application", request)
      .then((res) => {
        setShowSuccess(true);
        dispatch(setIsGetStarted(true));
      })
      .catch((ex) => {
        setshowFaild(true);
        setTimeout(() => {
          setShowError(true);
          if (
            ex.response &&
            ex.response.data &&
            ex.response.data.error !== "" &&
            ex.response.data.error !== undefined
          ) {
            setTransmitAppError(ex.response.data.error);
          } else {
            setTransmitAppError(
              "We are not able to send your application at this time. Please try again."
            );
          }
        }, 5000);
      });
  }, [storAccountInfo]);

  const onContinueClickHandler = () => {
    dispatch(setIsAppConfirmation(true));
    navigate("/Home");
  };

  const onStartoverClickHandler = () => {
    dispatch(setIsGetStarted(true));
    navigate(0);
  };

  return (
    <Fragment>
      <div className="transmition-middle-section">
        {showError ? (
          <h2>There’s a problem with your application.</h2>
        ) : (
          <h1>Sending your application...</h1>
        )}

        <div className="transmition-light-gray-box loading-box">
          {showSuccess && (
            <Lottie
              options={SuccessOptions}
              height={window.innerWidth > 640 ? 102 : 102}
              width={window.innerWidth > 640 ? 102 : 102}
            />
          )}
          {showFaild && (
            <Lottie
              options={faildOptions}
              height={window.innerWidth > 640 ? 102 : 102}
              width={window.innerWidth > 640 ? 102 : 102}
            />
          )}
          {!showSuccess && !showFaild ? (
            <>
              <div className="transmit-progress-bar">
                <span> {Math.trunc(percentComplete * 100)}%</span>

                <ProgressIndicator
                  barHeight={8}
                  percentComplete={percentComplete}
                />
              </div>
            </>
          ) : (
            <>
              {showError ? (
                <div className="transmit-error-box">
                  <DismissCircle />
                  <p>{transmitAppError}</p>
                </div>
              ) : (
                <div className="transmit-progress-bar">
                  <span> 100%</span>
                  <ProgressIndicator barHeight={8} percentComplete={1} />
                </div>
              )}
            </>
          )}
        </div>
        <div className="transmition-action-footer">
          <ActionButtons
            primarytext={
              showSuccess ? "Continue" : showFaild ? "Startover" : null
            }
            element={
              !showSuccess && !showFaild ? (
                <Spinner className="btn-spinner" size={SpinnerSize.small} />
              ) : null
            }
            hideSecondaryButton={true}
            onPrimaryButtonClickHandler={
              !showSuccess ? onStartoverClickHandler : onContinueClickHandler
            }
          />
        </div>
      </div>
    </Fragment>
  );
};
export default TransmitApp;
