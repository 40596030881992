import React, { Fragment } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/footerLogo.svg";
import SupportIcon from "../../assets/Support.svg";

const Footer = () => {
  let year = new Date().getFullYear();

  return (
    <Fragment>
      <footer>
        <div className="footer-box-1">
          <img src={LogoIcon} />
          <p className="footer-text">
            Copyright © {year} PCS Software, Inc. - All Rights Reserved.
          </p>
        </div>

        <div className="footer-box-2">
          <img style={{ verticalAlign: "middle" }} src={SupportIcon} />
          <p className="footer-text" style={{ fontWeight: "bold" }}>
            Support
          </p>
          <Link
            className="footer-text"
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:support@pcssoft.com";
              e.preventDefault();
            }}
          >
            support@pcssoft.com
          </Link>
          <div className="separation"></div>
          <p className="footer-text">1 (800) 474-8239</p>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
