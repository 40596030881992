import globalStyleVariables from "./GlobalStyleVariables";
import { mergeStyles } from "@fluentui/react";

export const ScrollablePaneGlobalStyle = {
  root: {
    selectors: {
      ".ms-ScrollablePane--contentContainer": {
        scrollbarWidth: globalStyleVariables.sbWidth,
        scrollbarColor: `${globalStyleVariables.sbThumbBg} ${globalStyleVariables.sbBg}`,
      },
      ".ms-ScrollablePane--contentContainer::-webkit-scrollbar": {
        width: globalStyleVariables.sbWidth,
        height: globalStyleVariables.sbHeight,
      },
      ".ms-ScrollablePane--contentContainer::-webkit-scrollbar-track": {
        background: globalStyleVariables.sbBg,
      },
      ".ms-ScrollablePane--contentContainer::-webkit-scrollbar-thumb": {
        background: globalStyleVariables.sbThumbBg,
        borderRadius: "45px",
      },
    },
  },
};

export const createScheduleScrollablePanelClassName = mergeStyles({
  position: "relative",
  height: "100%",
});
