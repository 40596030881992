import React, { Fragment, useState } from "react";
import "./WelcomeView.css";
import apiClient from "../../api/http_client";
import { ReactComponent as Info } from "../../assets/Info.svg";
import { ReactComponent as Question } from "../../assets/question.svg";
import { ReactComponent as Free } from "../../assets/Free.svg";
import ActionButtons from "../../components/buttons/ActionButtons";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface WelcomeViewProps {
  setPercentComplete: React.Dispatch<React.SetStateAction<number>>;
  setShowPage: React.Dispatch<React.SetStateAction<string>>;
}

const WelcomeView = (props: WelcomeViewProps) => {
  const [isUserAuthorised, setIsUserAuthorised] = useState<boolean>(false);
  const storAccountInfo = useSelector(
    (state: RootState) => state.accountInfo.AccountInfo
  );

  const nextHandler = () => {
    setIsUserAuthorised(true);
    apiClient
      .get("/user_authorization?emailAddress=" + storAccountInfo.emailAddress)
      .then((res) => {
        setIsUserAuthorised(false);
        props.setShowPage("business");
        props.setPercentComplete(0.2);
      })
      .catch((e) => {
        setIsUserAuthorised(false);
        toast.error("Error on login, please try again.", {
          closeButton: true,
          autoClose: 1000,
        });
      });
  };

  return (
    <Fragment>
      <div className="get-started-middle-section">
        <div className="get-started-box-header">
          <h1>{"Welcome, " + storAccountInfo.contactName + "!"}</h1>
          <div className="get-started-top-gray-box">
            <Info />
            <p>It's quick and easy, should only take 5 minutes</p>
          </div>
        </div>

        <p className="get-started-p">Get setup and approved in 5 steps </p>

        <div className="get-started-body">
          <div className="get-started-light-gray-box">
            <div className="get-started-question-gray-boxes">
              <div className="get-started-question-gray-box-1"></div>
              <div className="get-started-question-gray-box-2">
                <Question />
              </div>
              <div className="get-started-question-gray-box-3"></div>
            </div>

            <p>
              Please provide some information about your business in order to
              complete your <b> application.</b>
            </p>
          </div>

          <div className="get-started-footer">
            <ActionButtons
              primarytext={isUserAuthorised ? null : "Get started"}
              hideSecondaryButton={true}
              onPrimaryButtonClickHandler={nextHandler}
              element={
                isUserAuthorised ? (
                  <Spinner className="btn-spinner" size={SpinnerSize.small} />
                ) : null
              }
            />
            <div className="home-content-btn">
              <div className="home-content-sign">
                <Free />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default WelcomeView;
