import React, { Fragment, useState, useCallback } from "react";
import "./FleetDetails.css";

import {
  Checkbox,
  ScrollablePane,
  ScrollbarVisibility,
  TextField,
} from "@fluentui/react";
import ActionButtons from "../../components/buttons/ActionButtons";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { addAccountInfo } from "../../store/reducers/accountInfo";
import { ScrollablePaneGlobalStyle } from "../../Styles/UI/ScrollablePaneGlobalStyle";

interface FleetDetailsProps {
  setPercentComplete: React.Dispatch<React.SetStateAction<number>>;
  setShowPage: React.Dispatch<React.SetStateAction<string>>;
  trailerTypes: trailerType;
  enteredTruckCount: number;
  enteredTrailerCount: number;
  setTrailerTypes: React.Dispatch<React.SetStateAction<trailerType>>;
  setEnteredTruckCount: React.Dispatch<React.SetStateAction<number>>;
  setEnteredTrailerCount: React.Dispatch<React.SetStateAction<number>>;
}

interface trailerType {
  id: number;
  isChecked: boolean;
  name: boolean;
  isSelectAll?: boolean;
}

const FleetDetails = (props: FleetDetailsProps) => {
  const dispatch = useDispatch();
  const storAccountInfo = useSelector(
    (state: RootState) => state.accountInfo.AccountInfo
  );

  const [truckCountErrormessage, setTruckCountErrormessage] =
    useState<boolean>(false);
  const [trailerCountErrormessage, setTrailerCountErrormessage] =
    useState<boolean>(false);
  const [trailerTypesErrormessage, setTrailerTypesErrormessage] =
    useState<boolean>(false);

  async function nextHandler() {
    const checkedTrailerTypes = Object.values(props.trailerTypes)
      .filter((value: trailerType[]) => {
        return value.find((v) => v.isChecked);
      })
      .flat()
      .filter((value) => value.isChecked);

    if (props.enteredTruckCount < 10 || !props.enteredTruckCount) {
      setTruckCountErrormessage(true);
    }
    if (props.enteredTrailerCount < 10 || !props.enteredTrailerCount) {
      setTrailerCountErrormessage(true);
    }
    if (checkedTrailerTypes.length === 0) {
      setTrailerTypesErrormessage(true);
    }

    if (
      props.enteredTruckCount >= 10 &&
      props.enteredTrailerCount >= 10 &&
      checkedTrailerTypes.length !== 0
    ) {
      const selectedTrailerTypes = checkedTrailerTypes.map((value) => {
        return value.name;
      });
      const fleetDetailsInfo = {
        trailerTypes: selectedTrailerTypes,
        truckCount: props.enteredTruckCount,
        trailerCount: props.enteredTrailerCount,
      };
      dispatch(
        addAccountInfo({
          ...storAccountInfo,
          ...fleetDetailsInfo,
        })
      );
      props.setPercentComplete(0.8);
      props.setShowPage("lane");
    }
  }

  const previousHandler = () => {
    props.setPercentComplete(0.4);
    props.setShowPage("authority");
  };

  const truckCountChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 5) {
        props.setEnteredTruckCount(parseInt(newValue!));
        setTruckCountErrormessage(false);
      }
    },
    [props.enteredTruckCount]
  );

  const trailerCountChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 5) {
        props.setEnteredTrailerCount(parseInt(newValue!));
        setTrailerCountErrormessage(false);
      }
    },
    [props.enteredTrailerCount]
  );

  const getTruckCountErrorMessage = (value: string) => {
    if (value !== "") {
      parseInt(value) < 10 && setTruckCountErrormessage(true);
      return "";
    }
  };

  const getTrailerCountErrorMessage = (value: string) => {
    if (value !== "") {
      parseInt(value) < 10 && setTrailerCountErrormessage(true);
      return "";
    }
  };

  const trailerTypeHandleClick = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean,
    id?: number
  ) => {
    const checkedTrailers: any = Object.entries(props.trailerTypes)
      .map(([key, value]) => {
        return {
          [key]: value.map((val, index) => {
            if (val.id === id) {
              return { ...val, isChecked: checked! };
            } else {
              return { ...val };
            }
          }),
        };
      })
      .reduce((acc, currentVal) => {
        return { ...acc, ...currentVal };
      }, {});
    setTrailerTypesErrormessage(false);
    props.setTrailerTypes(checkedTrailers);
  };

  const trailerTypeSelectAllHandleClick = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean,
    selectedKey?: string
  ) => {
    const checkedTrailers: any = Object.entries(props.trailerTypes)
      .map(([key, value]) => {
        return {
          [key]: value.map((val) => {
            if (key === selectedKey) {
              return { ...val, isChecked: checked! };
            } else {
              return { ...val };
            }
          }),
        };
      })
      .reduce((acc, currentVal) => {
        return { ...acc, ...currentVal };
      }, {});
    setTrailerTypesErrormessage(false);
    props.setTrailerTypes(checkedTrailers);
  };

  return (
    <Fragment>
      <div className="business-details-middle-section">
        <h1>Tell us about your carrier fleet </h1>

        <form className="business-details-light-gray-box fleet-details-form">
          <div className="fleet-detail-box-body">
            <div className="gray-box-header leet-detail-header-title">
              <p>Fleet information</p>
            </div>
            <TextField
              className="form-text-field"
              inputClassName="input-14"
              placeholder="Enter number of trucks "
              label="Number of trucks"
              type="number"
              required
              onGetErrorMessage={getTruckCountErrorMessage}
              errorMessage={
                truckCountErrormessage
                  ? "Please enter a valid truck count of more than 10."
                  : ""
              }
              validateOnFocusOut
              value={
                props.enteredTruckCount !== 0
                  ? props.enteredTruckCount.toString()
                  : ""
              }
              onChange={truckCountChangeHandler}
            />

            <TextField
              className="form-text-field"
              inputClassName="input-15"
              placeholder="Enter number of trailers "
              label="Number of trailers"
              type="number"
              required
              onGetErrorMessage={getTrailerCountErrorMessage}
              value={
                props.enteredTrailerCount !== 0
                  ? props.enteredTrailerCount.toString()
                  : ""
              }
              errorMessage={
                trailerCountErrormessage
                  ? "Please enter a valid trailer count of more than 10."
                  : ""
              }
              validateOnFocusOut
              onChange={trailerCountChangeHandler}
            />
          </div>
          <h3 className="trailer-types-body-title">Trailer types</h3>
          <div className="trailer-types-body">
            <ScrollablePane
              scrollbarVisibility={ScrollbarVisibility.auto}
              styles={ScrollablePaneGlobalStyle}
            >
              {Object.entries(props.trailerTypes).map(([key, value]) => {
                return (
                  <div className="trailer-types-body-box">
                    <div className="gray-box-header trailer-types-header">
                      <p>{key}</p>
                    </div>
                    <div className="trailer-types-list">
                      <Checkbox
                        checked={
                          value.filter((v) => v.isChecked).length ===
                          value.length
                        }
                        label="Select all"
                        onChange={(ev, checked) =>
                          trailerTypeSelectAllHandleClick(ev, checked, key)
                        }
                      />
                      {value.map((v) => {
                        return (
                          <div className="trailer-types-list-item">
                            <Checkbox
                              checked={v.isChecked}
                              label={v.name}
                              onChange={(ev, checked) =>
                                trailerTypeHandleClick(ev, checked, v.id)
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </ScrollablePane>
          </div>
        </form>
        {trailerTypesErrormessage && (
          <span className="toggl-error-message">
            Please select trailer types.
          </span>
        )}
        <div className="business-details-footer">
          <ActionButtons
            primarytext={"Next"}
            secondarytext={"Previous"}
            onPrimaryButtonClickHandler={nextHandler}
            onDefaultButtonClickHandler={previousHandler}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FleetDetails;
