import React, { Fragment } from "react";
import "../../pages/LandingPage/LandingPageBanner.css";
import { ReactComponent as CustomerIcon } from "../../assets/customerIcon.svg";
import { ReactComponent as Marketplace } from "../../assets/marketplace.svg";
import { ReactComponent as Fleets } from "../../assets/fleets.svg";

interface IUserGuide {
  icon: JSX.Element;
  title: string;
  text: string;
}
const UserGuides = () => {
  const UserGuides: IUserGuide[] = [
    {
      icon: <CustomerIcon />,
      title: "Connect with customers",
      text: "We connect you with customers by displaying your fleet availability to shippers on our platform's carrier address book.",
    },
    {
      icon: <Marketplace />,
      title: "Join our marketplace",
      text: "Publish your available lanes and assets to shippers and carriers.",
    },
    {
      icon: <Fleets />,
      title: "Calling all fleets",
      text: "Commercial carriers and private shipper fleets",
    },
  ];

  return (
    <Fragment>
      <div className="home-content-guides-section">
        {UserGuides.map((item: IUserGuide, index: number) => {
          return (
            <div className="home-content-guides-element" key={index}>
              {item.icon}
              <div className="home-content-guides-text">
                <h4>{item.title}</h4>
                <span>{item.text}</span>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default UserGuides;
