import React, { Fragment, useCallback } from "react";
import "./LandingPageBanner.css";
import UserGuides from "../../components/userGuides/UserGuides";
import { ReactComponent as Free } from "../../assets/Free.svg";
import ActionButtons from "../../components/buttons/ActionButtons";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";

interface LandingPageBannerProps {
  showCarrierLogin: boolean;
  setCarrierLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const LandingPageBanner = (props: LandingPageBannerProps) => {
  const navigate = useNavigate();

  const registerHandler = useCallback(() => {
    navigate("/CreateNewAccount");
  }, []);

  const loginHandler = useCallback(() => {
    props.setCarrierLogin(true);
  }, [props.showCarrierLogin]);

  return (
    <Fragment>
      <div className="home-content-container">
        <Header
          title={"Connecting carriers and shippers for seamless logistics."}
          showDescription={false}
          hidLogo={true}
        />
        <div className="home-content-body">
          <p>Sign up to apply and win with us!</p>
          <ActionButtons
            primarytext={"Sign up"}
            hideSecondaryButton={true}
            onPrimaryButtonClickHandler={registerHandler}
          />
          <div className="home-content-btn">
            <div className="home-content-sign">
              <Free />
            </div>
          </div>
          <div className="home-content-divider-section">
            <div className="divider-left"></div>
            <div className="divider-text">OR</div>
            <div className="divider-right"></div>
          </div>
          <ActionButtons
            secondarytext={"Sign in"}
            hidePrimaryButton={true}
            onDefaultButtonClickHandler={loginHandler}
          />
        </div>
        <UserGuides />
        <div className="home-content-divider-section">
         <p> Your information will be used in accordance with our <a href="https://pcssoft.com/privacy-policy/" target="_blank">Privacy Policy</a>
         </p>
        </div>
      </div>
    </Fragment>
  );
};

export default LandingPageBanner;
