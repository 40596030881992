import React from "react";
import "./NotFound.css";
import notFoundTruckImage from "../../assets/not-found-truck-view.svg";
import { useResizeHandler } from "../../hooks/useResizeHandler";
import { useNavigate, useLocation } from "react-router-dom";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { PrimaryButton } from "@fluentui/react";

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reponsive = 640;
  const imgHeight = useResizeHandler(250).height;
  const mHeight = useResizeHandler(140).height;
  const mHeightResponsive = useResizeHandler(200).height;

  const matchedContainer = mergeStyles({
    height: mHeight,
  });

  const containerImage = mergeStyles({
    height: imgHeight,
  });

  const goBackOrHome = () => {
    if (goHome()) navigate("/");
    else navigate(-1);
  };

  const goHome = () => {
    return location.pathname.includes("not-found");
  };

  const btnText = () => {
    return goHome() ? "Go home" : "Go back";
  };

  return (
    <>
      <div className={window.innerWidth > reponsive ? matchedContainer : ""}>
        <div className="not-found-container">
          <div>
            <span className="header-text">
              <b>{"Whoops"}</b>
              {", I think we gave you bad directions..."}
            </span>

            <div className="details-text">
              <span>
                {
                  "Sorry about that, let’s try to get you back to somewhere familiar."
                }
              </span>
            </div>
            <div className="btn-box">
              <PrimaryButton onClick={goBackOrHome}>{btnText()}</PrimaryButton>
            </div>
          </div>

          <div className="not-found-container-image">
            <img
              className={window.innerWidth > reponsive ? containerImage : ""}
              src={notFoundTruckImage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
