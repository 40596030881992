import React, { useState, useCallback, Fragment } from "react";
import "./VerifyEmail.css";
import { isInt, validEmail } from "../../utils/FormValidations";
import { useResizeHandler } from "../../hooks/useResizeHandler";
import ActionButtons from "../../components/buttons/ActionButtons";
import Header from "../../components/header/Header";
import { Spinner, SpinnerSize, TextField } from "@fluentui/react";
import SuccessVerifiedEmail from "../SuccessVerifiedEmail/SuccessVerifiedEmail";

interface VerifyEmailProps {
  enteredCode: string;
  isEmailVerified: boolean;
  enteredEmail: string;
  codeErrormessage: boolean;
  isSuccessVerifiedEmail: boolean;
  setIsSuccessVerifiedEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setEnteredCode: React.Dispatch<React.SetStateAction<string>>;
  setEnteredEmail: React.Dispatch<React.SetStateAction<string>>;
  VerifyEmailHandler: () => void;
  verifyEmailPreviousHandler: () => void;
  setCodeErrormessage: React.Dispatch<React.SetStateAction<boolean>>;
}

const VerifyEmail = (props: VerifyEmailProps) => {
  const nextHandler = () => {
    if (!isInt(parseInt(props.enteredCode))) {
      props.setCodeErrormessage(true);
    } else {
      props.VerifyEmailHandler();
    }
  };

  const previousHandler = () => {
    props.verifyEmailPreviousHandler();
  };

  const verificationCodeChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredCode(newValue || "");
        props.setCodeErrormessage(false);
      }
    },
    [props.enteredCode]
  );

  const getCodeErrorMessage = (value: string) => {
    if (value !== "") {
      value === "" && props.setCodeErrormessage(true);
      return "";
    }
  };
  const responsiveInnerHight = useResizeHandler(145).height;
  const height = useResizeHandler(70).height;
  return (
    <Fragment>
      <div
        className="verify-email-container"
        style={{
          height: window.innerWidth < 380 ? responsiveInnerHight : height,
        }}
      >
        <Header
          title={"Verify your account"}
          description={"Please verify your email address"}
          showDescription={true}
        />
        {props.isSuccessVerifiedEmail ? (
          <SuccessVerifiedEmail
            enteredEmail={props.enteredEmail}
            setIsSuccessVerifiedEmail={props.setIsSuccessVerifiedEmail}
          />
        ) : (
          <>
            <form>
              <TextField
                required
                disabled
                inputClassName="input-23"
                className="form-text-field"
                placeholder="Enter email"
                type="email"
                label="Email Address"
                value={props.enteredEmail}
              />
              <TextField
                required
                inputClassName="input-24"
                className="form-text-field"
                placeholder="Enter code"
                label="Verification code"
                errorMessage={
                  props.codeErrormessage ? "Please enter a valid code." : ""
                }
                validateOnFocusOut
                onGetErrorMessage={getCodeErrorMessage}
                onChange={verificationCodeChangeHandler}
                value={props.enteredCode}
              />
            </form>
            <ActionButtons
              primarytext={props.isEmailVerified ? null : "Verify code"}
              secondarytext={"Back"}
              onPrimaryButtonClickHandler={nextHandler}
              onDefaultButtonClickHandler={previousHandler}
              element={
                props.isEmailVerified ? (
                  <Spinner className="btn-spinner" size={SpinnerSize.small} />
                ) : null
              }
            />
          </>
        )}
      </div>
    </Fragment>
  );
};

export default VerifyEmail;
