import React, { Fragment } from "react";
import "./LaneDetails.css";
import ActionButtons from "../../components/buttons/ActionButtons";

interface TermsAndConditionsViewProps {
  setshowTermsAndConditionsAccepted: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const TermsAndConditionsView = (props: TermsAndConditionsViewProps) => {
  const doneHandler = () => {
    props.setshowTermsAndConditionsAccepted(false);
  };

  return (
    <Fragment>
      <div className="business-details-middle-section">
        <div className="business-details-light-gray-box">
        <b>FreightNet Terms of Service</b>
            <p>
            THE FOLLOWING TERMS AND CONDITIONS GOVERN ALL USE OF THE https://freightnet.ai WEBSITE (THE “SITE”) AND ALL OF THE ‘FREIGHTNET’ SERVICES AVAILABLE AT THE WEBSITE (TAKEN TOGETHER WITH THE SITE, THE “SERVICE”).  THE SERVICE IS PROVIDED TO YOU BY PCS SOFTWARE, INC. (“PCS”). ACCESS TO, OR USE OF, THE SERVICE IS SUBJECT TO YOUR, AND THE ENTITY YOU REPRESENT, (TOGETHER, “CUSTOMER”) COMPLIANCE WITH THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT ACCESS OR USE THE SERVICE.  BY VIEWING. ACCESSING, OR USING ANY PART OF THE SERVICES YOU AGREE TO BE BOUND BY THIS AGREEMENT.
            Certain services available through the Site may be subject to additional terms and conditions.  To the extent such additional terms or conditions conflict with this Agreement, such terms/conditions shall control.
            </p>
            <p>
            <b>1.	Scope</b><br/>
            The Site is a neutral platform where companies looking to ship products (“Shippers”) can locate and engage third-party trucking carriers (“Carriers”) to provide shipping services (“Carrier Services”). As a neutral platform, PCS does not endorse, guarantee, test, inspect, assess, or otherwise warrant and Carrier Services. 
            </p>
            <p>
            <b>2.	Carrier Terms and Conditions</b><br/>
            Carrier Services may be subject to terms and conditions provided by the applicable Carrier (“Carrier Terms”). 
            FOR CLARITY, PCS IS NOT A CARRIER. PCS DOES NOT MAKE ANY WARRANTIES WITH RESPECT TO CARRIER SERVICES. WARRANTIES, IF ANY, ARE ONLY AS PROVIDED TO SHIPPER BY THE APPLICABLE CARRIER AS PART OF THE CARRIER TERMS.
            Carrier Terms are a legal contract between Shipper and the applicable Carrier. PCS is not a party thereto and has no liability or responsibility thereunder. 
            As such, if any Carrier Services are delayed, defective, or otherwise have issues, Shipper’s sole remedy shall be as allowed by the Carrier Terms. If there is any issue, Shipper should reach out to the Carrier directly. PCS has no legal obligation with respect to such Carrier Service-related issues. Any assistance by PCS is solely as a courtesy.
            </p>
            <p>
            <b>3.	Carrier Information</b><br/>
            PCS IS NOT A CARRIER. PCS DOES NOT SET OR OTHERWISE CONTROL THE PRICING, AVAILABILITY, OR SHIPPING OF ANY CARRIER SERVICES. 
            ALL PRICING, AVAILABILITY, AND OTHER CARRIER SERVICE-RELATED INFORMATION (INCLUDING, ACCIDENT RATINGS) IS PROVIDED BY THE APPLICABLE THIRD-PARTY CARRIERS OR RETREIVED BY PCS FROM THIRD-PARTY WEBSITES AND RESROUCES (SUCH AS THE FEDERAL MOTOR CARRIER ASSOCIATION). AS SUCH, PCS MAKES NO WARRANTIES TO THE ACCURACY OR TIMELINESS OF ANY SUCH INFORMATION. CUSTOMER ACKNOWLEDGES THAT SUCH INFORMATION (AND SUCH THIRD-PARTY WEBSITES AND RESOURCES) MAY BE INACCURATE, DELAYED, OR OUT-OF-DATE. 
            </p>
            <p>
            <b>4.	Account</b><br/>
            To use the Service, Customer will be required to create a Service account (“Account”). Customer is responsible for ensuring all information Customer submits to the account (such as Customer’s contact information and payment details) is always accurate, correct, and up-to-date. Customer is responsible for maintaining the secrecy of its account credentials.
            </p>
            <p>
            <b>5.	Restrictions</b> <br/>
            As a condition of use, Customer promises not to use the Service for any purpose that is unlawful or prohibited by this Agreement, or any other purpose not reasonably intended by PCS as expressed by the features and functionality of the Service.  Customer agrees to abide by all applicable local, state, national and international laws and regulations.
            By way of example, and not as a limitation, Customer agrees not to (a) take any action or (b) upload, post, submit or otherwise distribute or facilitate distribution of any content (including text, communications, software, images, sounds, data or other information) using any communications service or other service available on or through the Service, that:
            <ul>
            <li>is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, profane or which otherwise violates this Agreement;</li>
            <li>infringes any patent, trademark, trade secret, copyright, right of publicity or other right of any party;</li>
            <li>constitutes unauthorized or unsolicited advertising, junk or bulk e-mail ("spamming");</li>
            <li>imposes an unreasonable or disproportionately large load on PCS’s computing, storage or communications infrastructure, or attempts to gain unauthorized access to the Service, other accounts, computer systems or networks connected to the Service, through password mining or otherwise;</li>
            <li>contains software viruses or any other computer codes, files, or programs that are designed or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data or other information of PCS or any third-party; or</li>
            <li>harvests or collects any information from the Site; or</li>
            <li>impersonates any person or entity, including any employee or representative of PCS.</li>
            </ul>
            <br/>
            PCS may, at its sole discretion, immediately terminate Customer’s access to the Service should Customer’s conduct fail to conform strictly with any provision of this section.
            </p>
            <p>
            <b>6.	Fees; Payment</b><br/>
            Access to certain Service features and functions may require payment to PCS of fees (“Fees”). Fees will be as stated by PCS on the Site (or otherwise in writing). All Fees are non-cancellable and non-refundable. Fees are due as set out on the site. Customer must ensure the payment information it provides to PCS (such as its credit card information is correct and up-to-date at all times). Customer hereby authorizes PCS (and its payment processor) to charge Customer’s payment account for all Fees. 
            Payment for all Orders is due when the Order is placed. If PCS invoices Customer, payment must be made within fifteen (15) days from the date of invoice. If Customer has provided PCS with a credit card or bank account information for payment purposes, it hereby authorizes PCS (and its third-party payment provider) to charge the card/account upon placement of the Order.
            Late fees are subject to a finance charge of 1.5% for each thirty (30) days late (or, if less, the maximum allowed by law). In addition, Customer will reimburse PCS for all costs of collection (including attorneys’ fees). The fees and costs above are in addition to any other rights and remedies PCS has in this Agreement.
            </p>
            <p>
            <b>7.	Feedback</b><br/>
            If Customer provides PCS with any suggestions for improvements to, or other feedback with respect to, the Service (“Feedback”) Customer grants PCS a perpetual, irrevocable, royalty free, paid-up, sub-licensable, right and license to use, display, reproduce, distribute and otherwise exploit Feedback for any purposes. PCS agrees that (i) Customer does not have to provide Feedback, and (ii) all Feedback is provided “AS IS”.
            </p>
            <p>
            <b>8.	Reviews</b><br/>
            The Site may allow Shippers to leave reviews or ratings to Carriers. When posting a review, Shippers must comply with the following criteria: (1) Customer must have actually used the applicable Carrier Services; (2) reviews must not contain offensive profanity, or abusive, racist, offensive, or hate language; (3) reviews must not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability; (4) reviews must not contain references to illegal activity; (5) Shippers not be affiliated with competitors if posting negative reviews; (6) Shipper should not make any conclusions as to the legality of conduct; (7) Shipper must not post any false or misleading statements; and (8) Shipper must not organize a campaign encouraging others to post reviews, whether positive or negative. 

            PCS may accept, reject, or remove reviews in our sole discretion. PCS has absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by PCS, and do not necessarily represent our opinions or the views of any of PCS’ affiliates or partners. PCS does not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, Shipper hereby grant to PCS a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.
            </p>
            <p>
            <b>9.	Links on the Site</b> <br/>
            From time-to-time PCS may link to websites that PCS determines may be of interest to Site visitors.  These websites may contain content, products, or services owned or controlled by other third parties. 
            PCS is not responsible for the content, information, products, or services of any linked site, any link(s) contained in any linked site, or any changes or updates to the information contained in such sites.  PCS provides links to third-party sites only as a convenience and the inclusion of any such link on the Site does not imply PCS’s endorsement of either the site, the organization operating such site, or any products or services of that organization.  A visit to any site or page from the Site via any such link is done entirely at Customer’s own risk. 
            Under no circumstances will PCS be held responsible or liable, directly or indirectly, for any loss or damage that is caused or alleged to have been caused in connection with the use of, or reliance on, any content, goods or services available on any other site. 
            </p>
            <p>
            <b>10.	Disclaimer of Warranties </b><br/>
            THE SERVICE (INCLUDING, WITHOUT LIMITATION, ALL MATERIALS, INFORMATION, PRODUCTS AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SERVICE) ARE PROVIDED "AS IS" AND "AS AVAILABLE".  THE SERVICE IS PROVIDED WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE HEREBY EXPRESSLY DISCLAIMED.  
            CUSTOMER ACKNOWLEDGES AND AGREES THAT PCS MAKES NO WARRANTIES, OR ANY KIND, WITH RESPECT TO ANY SHIPPERS OR CARRIERS. CUSTOMER ACKNOWLEDGES THAT PCS DOES NOT VET, AUDIT, OR OTHERWISE INSPECT AND SHIPPERS OR CARRIERS. ANY WARRANTIES WITH RESPECT TO ANY CARRIER SERVICES ARE ONLY AS PROVIDED BY THE APPLICABLE CARRIETR DIRECTLY TO THE APPLICABLE SHIPPER PURSUANT TO THEIR SHIPPING CONTRACT.
            SHIPPERS ALSO ACKNOWLEDGE AND AGREE THAT PCS DOES NOT CREATE OR VERIFY ANY CARRIER REVIEWS POSTED TO THE SITE. SHIPPER RELIES ON CARRIER REVIEW AT ITS OWN DISCRETION AND RISK.
            FURTHERMORE, CUSTOMER ACKNOWLEDGES AND AGREES THAT PCS DOES NOT WARRANT THAT ANY SHIPPER OR CARRIER CONTENT OR INFORMATION LOADED OR POSTED TO THE SITE IS ACCURATE, CORRECT, TRUTHFUL, OR UP-TO-DATE.
            SOME STATES DO NOT ALLOW CERTAIN DISCLAIMERS OF WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO CUSTOMER.
            </p>
            <p>
            <b>11.	Limitations on Liability</b> <br/>
            IN NO EVENT SHALL PCS, NOR ITS DIRECTORS, EMPLOYEES, AGENTS, CONTRACTORS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL THEORY WITH RESPECT TO THE SERVICE FOR ANY: (I) LOST PROFITS OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, SUBSTITUTE SERVICES (HOWEVER ARISING), EVEN IF FORESEEABLE, (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) $500.00 (U.S.).  THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER PROVISIONS OF THIS AGREEMENT AND SHALL APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY PROVIDED HEREIN.  
            CUSTOMER ACKNOWLEDGES AND AGREES THAT PCS HAS NO LIABILITY FOR ANY SHIPPER PRODUCTS OR CARRIER SERVICES OR THE ACTS, OMISSIONS, WARRANTIES, PROMISES, OR GUARENTEES, PROVIDED OR MADE BY ANY SHIPPERS OR CARRIERS.
            SOME STATES DO NOT ALLOW THE LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO CUSTOMER.
            </p>
            <p>
            <b>12.	Indemnification</b><br/>
            Customer shall indemnify and hold harmless PCS and its officers, directors, shareholders, and employees, from and against all claims, liabilities, damages, losses, costs and expenses, including but not limited to attorneys fees, in whole or in part arising out of or attributable to (i) any breach of this Agreement (or any law or regulation) by Customer, (ii) Customer’s negligence or misconduct, (iii) Customer’s late payment of any fees owed hereunder, or (iv) any dispute(s) or issue(s) Customer has with any Shipper or Carrier.  PCS reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by Customer, in which event Customer will assist and cooperate with PCS in asserting any available defenses.
            </p>
            <p>
            <b>13.	Termination</b><br/>
            PCS may terminate Customer’s access to all or any part of the Service at any time, with or without cause, with or without notice, effective immediately.  If Customer wish to terminate its Account, it may simply discontinue using the Service. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. 
            </p>
            <p>
            <b>14.	Disputes between Shippers and Carriers</b><br/>
            Customer acknowledges and agrees that PCS is under no obligation to become involved in any disputes between Customer and any Shipper or Carrier. If PCS elects to help resolve any such dispute, it does so only as a courtesy. In the event that Customer has such a dispute, Customer releases PCS, its officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or the Service. If Customer is a California resident, Customer shall and hereby does waive California Civil Code Section 1542, which says: "A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which, if known by him or her must have materially affected his or her settlement with the debtor." 
            </p>
            <p>
            <b>15.	Governing Law; Disputes</b><br/>
            This Agreement shall be governed by and construed in accordance with the laws of the state of Texas without regard to the conflict of laws provisions thereof. Any dispute arising from or relating to the subject matter of this Agreement shall be finally settled by arbitration in Texas using the English language in accordance with the Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS") then in effect, by one commercial arbitrator, who shall be selected from the appropriate list of JAMS arbitrators in accordance with the Arbitration Rules and Procedures of JAMS.  If the JAMS streamlined rules and procedures are available, they will be used. Judgment upon the award so rendered may be entered in a court having jurisdiction or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be.  Notwithstanding the foregoing, PCS shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator.  If a claim cannot legal be arbitrated (as determined by the arbitrator), the parties consent to exclusive jurisdiction of, and venue in, the state and federal courts located in Texas.
            </p>
            <p>
            <b>16.	Notices</b><br/>
            PCS may provide any notices to Customer via email of Account notification. Legal notices from Customer to PCS must be mailed by registered or certified mail (return receipt requested) to 2103 Citywest Boulevard. Building 4, Suite 700. Houston, TX 77041.  In addition, any legal notices to PCS (such as for breach of this Agreement) must be delivered to the following email address: accounting@pcssoft.com (“Legal” – must be included in the subject heading); but, notwithstanding earlier receipt via email, legal notices to PCS will be deemed received when the physical notice is received as set forth in preceding sentence.
            For general questions (non-legal), Customer may contact PCS at freightnet@pcssoft.com,
            </p>
            <p>
            <b>17.	Modifications</b><br/>
            PCS reserves the right, at its sole discretion, to modify or replace any provision of this Agreement at any time.  It is Customer’s responsibility to check this Agreement periodically for changes.  Customer’s continued use of the Service following the posting of any changes to this Agreement constitutes acceptance of those changes.
            </p>
            <p>
            <b>18.	General</b> <br/>
            The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. PCS shall not be liable for any failure to perform its obligations hereunder where such failure results from any cause beyond PCS’s reasonable control, including, without limitation, issues with Shippers or Carriers, mechanical, electronic or communications failure or degradation. If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and enforceable. This Agreement is not assignable, transferable or sublicensable by Customer except with PCS’s prior written consent.  PCS may transfer, assign or delegate this Agreement and its rights and obligations without consent.  Both parties agree that this Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement, and that all modifications must be in a writing signed by both parties, except as otherwise provided herein. 
            </p>
            <p>
            <b>19.	Copyright and Trademark Notices.</b><br/>
            Unless otherwise indicated, this Agreement and all content provided by PCS is copyright © 2023 PCS Software, Inc.  All rights reserved.
            “PCS Software” as well as page headers, custom graphics, buttons, images and other content on the Site, are subject to trademark, service mark, trade dress, copyright and/or other proprietary or intellectual property rights or licenses held by PCS Suppliers, Inc. or its licensors, supplier or partners.  Other trademarks, product names and company names or logos used on the Site are the property of their respective owners.  Except as expressly authorized, the use or misuse of any trademarks, trade names, logos, images, graphics or content from the Site is strictly prohibited.
            </p>
        </div>
        <div className="business-details-footer">
          <ActionButtons
            primarytext={"Done"}
            hideSecondaryButton={true}
            onPrimaryButtonClickHandler={doneHandler}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TermsAndConditionsView;
