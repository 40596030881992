import React, { useState, useCallback, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "./CarrierLogin.css";
import { toast, ToastContainer } from "react-toastify";
import { validEmail, strongPassword } from "../../utils/FormValidations";
import apiClient from "../../api/http_client";
import { Spinner, SpinnerSize, TextField } from "@fluentui/react";
import ActionButtons from "../../components/buttons/ActionButtons";
import { useResizeHandler } from "../../hooks/useResizeHandler";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { addAccountInfo } from "../../store/reducers/accountInfo";
import { setIsAppConfirmation } from "../../store/reducers/userAuthentication";

interface CarrierLoginProps {
  setCarrierLogin: React.Dispatch<React.SetStateAction<boolean>>;
  onLogin?: (emailAddress: string, password: string) => void;
}

const CarrierLogin = (props: CarrierLoginProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storAccountInfo = useSelector(
    (state: RootState) => state.accountInfo.AccountInfo
  );
  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [enteredPassword, setEnteredPassword] = useState<string>("");

  const [isUserLogin, setIsUserLogin] = useState<boolean>(false);
  const [emailErrormessage, setEmailErrormessage] = useState<boolean>(false);
  const [passwordErrormessage, setPasswordErrormessage] =
    useState<boolean>(false);

  const nextHandler = () => {
    if (!validEmail(enteredEmail)) {
      setEmailErrormessage(true);
    } else if (!strongPassword(enteredPassword)) {
      setPasswordErrormessage(true);
    } else {
      setIsUserLogin(true);
      const request = {
        emailAddress: enteredEmail,
        password: enteredPassword,
      };
      apiClient
        .post("/login_token", request)
        .then(() => {
          const carrierLoginInfo = {
            emailAddress: enteredEmail,
            password: enteredPassword,
          };
          dispatch(
            addAccountInfo({
              ...storAccountInfo,
              ...carrierLoginInfo,
            })
          );
          dispatch(setIsAppConfirmation(true));
          navigate("/Home");
          setIsUserLogin(false);
        })
        .catch((e) => {
          toast.error("Error: invalid password or username", {
            closeButton: true,
            autoClose: 1000,
          });
          setIsUserLogin(false);
          setEnteredEmail("");
          setEnteredPassword("");
          setEmailErrormessage(false);
          setPasswordErrormessage(false);
        });
    }
  };

  const previousHandler = () => {
    props.setCarrierLogin(false);
  };

  const emailChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || validEmail(newValue) || newValue.length <= 50) {
        setEnteredEmail(newValue || "");
        setEmailErrormessage(false);
      }
    },
    [enteredEmail]
  );

  const passwordChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        setEnteredPassword(newValue || "");
        setPasswordErrormessage(false);
      }
    },
    [enteredPassword]
  );

  const getEmailErrorMessage = (value: string) => {
    if (value !== "") {
      !validEmail(value) && setEmailErrormessage(true);
      return "";
    }
  };

  const getPasswordErrorMessage = (value: string) => {
    if (value !== "") {
      value === "" && setPasswordErrormessage(true);
      return "";
    }
  };
  const responsiveInnerHight = useResizeHandler(145).height;
  const height = useResizeHandler(70).height;
  return (
    <Fragment>
      <div
        className="carrier-login-container"
        style={{
          height: window.innerWidth < 380 ? responsiveInnerHight : height,
        }}
      >
        <Header
          title={"Welcome back!"}
          description={"Please sign in below"}
          showDescription={true}
        />
        <form>
          <TextField
            required
            inputClassName="input-12"
            className="form-text-field"
            placeholder="Enter email"
            type="email"
            label="Email Address"
            onGetErrorMessage={getEmailErrorMessage}
            errorMessage={
              emailErrormessage ? "Please enter a valid email address." : ""
            }
            value={enteredEmail}
            onChange={emailChangeHandler}
            validateOnFocusOut
          />
          <TextField
            required
            inputClassName="input-13"
            className="form-text-field"
            placeholder="Enter password"
            label="Password"
            type="password"
            canRevealPassword
            errorMessage={
              passwordErrormessage ? "Please enter a valid password." : ""
            }
            validateOnFocusOut
            revealPasswordAriaLabel="Show password"
            onGetErrorMessage={getPasswordErrorMessage}
            value={enteredPassword}
            onChange={passwordChangeHandler}
          />
        </form>
        <ActionButtons
          primarytext={isUserLogin ? null : "Sign in"}
          secondarytext={"Back"}
          onPrimaryButtonClickHandler={nextHandler}
          onDefaultButtonClickHandler={previousHandler}
          element={
            isUserLogin ? (
              <Spinner className="btn-spinner" size={SpinnerSize.small} />
            ) : null
          }
        />
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default CarrierLogin;
