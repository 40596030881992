import "./ActionButtons.css";
import React, { Fragment } from "react";
import { PrimaryButton, DefaultButton, IButtonStyles } from "@fluentui/react";

interface IActionButtonsProps {
  primarytext?: string | null;
  hidePrimaryButton?: boolean;
  primaryDisabled?: boolean;
  secondarytext?: string;
  hideSecondaryButton?: boolean;
  secondaryDisabled?: boolean;
  element?: any;
  onPrimaryButtonClickHandler?: () => void;
  onDefaultButtonClickHandler?: () => void;
}

const ActionButtons = (props: IActionButtonsProps) => {
  const onPrimaryButtonClickHandler = () => {
    props.onPrimaryButtonClickHandler!();
  };

  const onDefaultButtonClickHandler = () => {
    props.onDefaultButtonClickHandler!();
  };

  const defaultButtonOnCheckedStyles: IButtonStyles = {
    rootChecked: {
      backgroundColor: "#308EA1",
      color: "white",
    },
  };

  return (
    <Fragment>
      <div className="action-buttons-container">
        {!props.hidePrimaryButton ? (
          <PrimaryButton
            text={props.primarytext!}
            disabled={props.primaryDisabled}
            onClick={onPrimaryButtonClickHandler}
          >
            {" "}
            {props.element}
          </PrimaryButton>
        ) : null}
        {!props.hideSecondaryButton ? (
          <DefaultButton
            text={props.secondarytext}
            onClick={onDefaultButtonClickHandler}
            styles={defaultButtonOnCheckedStyles}
            disabled={props.secondaryDisabled}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default ActionButtons;
