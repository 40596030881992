import React, { Fragment, useState, useCallback } from "react";
import { Spinner, SpinnerSize, TextField } from "@fluentui/react";
import "./CreateNewAccount.css";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as Free } from "../../assets/Free.svg";
import ActionButtons from "../../components/buttons/ActionButtons";
import Header from "../../components/header/Header";
import apiClient from "../../api/http_client";
import {
  validEmail,
  validPhoneNumber,
  strongPassword,
} from "../../utils/FormValidations";
import { AccountInfo, addAccountInfo } from "../../store/reducers/accountInfo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

interface NewAccountProps {
  enteredCompany: string;
  enteredEmail: string;
  enteredContact: string;
  enteredMobileNumber: string;
  enteredPassword: string;
  setEnteredCompany: React.Dispatch<React.SetStateAction<string>>;
  setEnteredEmail: React.Dispatch<React.SetStateAction<string>>;
  setEnteredContact: React.Dispatch<React.SetStateAction<string>>;
  setEnteredPassword: React.Dispatch<React.SetStateAction<string>>;
  setEnteredMobileNumber: React.Dispatch<React.SetStateAction<string>>;
  setshowVerifyEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateNewAccount = (props: NewAccountProps) => {
  const navigate = useNavigate();
  const [enteredCPassword, setEnteredCPassword] = useState<string>("");
  const [isCrateAccountLoading, setIsCrateAccountLoading] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const [companyErrormessage, setCompanyErrormessage] =
    useState<boolean>(false);
  const [contactErrormessage, setContactErrormessage] =
    useState<boolean>(false);
  const [emailErrormessage, setEmailErrormessage] = useState<boolean>(false);
  const [mobileNumberErrormessage, setMobileNumberErrormessage] =
    useState<boolean>(false);
  const [passwordErrormessage, setPasswordErrormessage] =
    useState<boolean>(false);
  const [cPasswordErrormessage, setCPasswordErrormessage] =
    useState<boolean>(false);

  const createAccountHandler = () => {
    if (props.enteredCompany.trim() === "") {
      setCompanyErrormessage(true);
    }
    if (props.enteredContact.trim() === "") {
      setContactErrormessage(true);
    }
    if (!validEmail(props.enteredEmail)) {
      setEmailErrormessage(true);
    }
    if (!validPhoneNumber(props.enteredMobileNumber)) {
      setMobileNumberErrormessage(true);
    }
    if (enteredCPassword !== props.enteredPassword || enteredCPassword === "") {
      setCPasswordErrormessage(true);
    }
    if (!strongPassword(props.enteredPassword)) {
      setPasswordErrormessage(true);
    }

    if (
      props.enteredCompany.trim() !== "" &&
      props.enteredContact.trim() !== "" &&
      validEmail(props.enteredEmail) &&
      validPhoneNumber(props.enteredMobileNumber) &&
      enteredCPassword === props.enteredPassword &&
      strongPassword(props.enteredPassword)
    ) {
      setIsCrateAccountLoading(true);
      apiClient
        .post("/verification", {
          companyName: props.enteredCompany,
          contactName: props.enteredContact,
          emailAddress: props.enteredEmail,
          mobileNumber: props.enteredMobileNumber,
        })
        .then((result) => {
          const profileInfo: AccountInfo = {
            companyName: props.enteredCompany,
            contactName: props.enteredContact,
            emailAddress: props.enteredEmail,
            password: props.enteredPassword,
            mobileNumber: props.enteredMobileNumber,
          };
          dispatch(addAccountInfo(profileInfo));
          props.setshowVerifyEmail(true);
          setIsCrateAccountLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsCrateAccountLoading(false);
          if (e.response && e.response.data) {
            toast.error(e.response.data.errorMessage, {
              closeButton: true,
              autoClose: 1000,
            });
          } else {
            toast.error("Error saving email address, please try again.", {
              closeButton: true,
              autoClose: 1000,
            });
          }
        });
    }
  };

  const backHandler = () => {
    navigate("/");
  };

  const companyChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredCompany(newValue || "");
        setCompanyErrormessage(false);
      }
    },
    [props.enteredCompany]
  );

  const emailChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || validEmail(newValue) || newValue.length <= 50) {
        props.setEnteredEmail(newValue || "");
        setEmailErrormessage(false);
      }
    },
    [props.enteredEmail]
  );

  const contactChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredContact(newValue || "");
        setContactErrormessage(false);
      }
    },
    [props.enteredContact]
  );

  const mobileNumberChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 10) {
        props.setEnteredMobileNumber(newValue || "");
        setMobileNumberErrormessage(false);
      }
    },
    [props.enteredMobileNumber]
  );

  const passwordChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredPassword(newValue || "");
        setPasswordErrormessage(false);
      }
    },
    [props.enteredPassword]
  );

  const cPasswordChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        setEnteredCPassword(newValue || "");
        setCPasswordErrormessage(false);
      }
    },
    [enteredCPassword]
  );

  const getCompanyErrorMessage = (value: string) => {
    if (value !== "") {
      value.length < 3 && setCompanyErrormessage(true);
      return "";
    }
  };

  const getContactErrorMessage = (value: string) => {
    if (value !== "") {
      value.length < 3 && setContactErrormessage(true);
      return "";
    }
  };

  const getEmailErrorMessage = (value: string) => {
    if (value !== "") {
      !validEmail(value) && setEmailErrormessage(true);
      return "";
    }
  };

  const getMobileNumberErrorMessage = (value: string) => {
    if (value !== "") {
      !validPhoneNumber(value) && setMobileNumberErrormessage(true);
      return "";
    }
  };

  const getPasswordErrorMessage = (value: string) => {
    if (value !== "") {
      !strongPassword(value) && setPasswordErrormessage(true);
      return "";
    }
  };

  const getCPasswordErrorMessage = (value: string) => {
    if (value !== "") {
      enteredCPassword !== props.enteredPassword &&
        setCPasswordErrormessage(true);
      return "";
    }
  };

  return (
    <Fragment>
      <div className="new-account-container">
        <Header
          title={"Sign up with us"}
          description={"Start by creating your account"}
          showDescription={true}
        />
        <form>
          <TextField
            className="form-text-field"
            placeholder="Enter company name"
            label="Company Name"
            required
            inputClassName="input-17"
            onGetErrorMessage={getCompanyErrorMessage}
            onChange={companyChangeHandler}
            errorMessage={
              companyErrormessage ? "Please enter a valid company name." : ""
            }
            validateOnFocusOut
            value={props.enteredCompany}
          />
          <TextField
            placeholder="Enter contact name"
            className="form-text-field"
            label="Contact Name"
            inputClassName="input-18"
            required
            onGetErrorMessage={getContactErrorMessage}
            onChange={contactChangeHandler}
            errorMessage={
              contactErrormessage ? "Please enter a valid contact name." : ""
            }
            validateOnFocusOut
            value={props.enteredContact}
          />
          <TextField
            required
            className="form-text-field"
            placeholder="Enter email"
            type="email"
            label="Email Address"
            onGetErrorMessage={getEmailErrorMessage}
            errorMessage={
              emailErrormessage ? "Please enter a valid email address." : ""
            }
            onChange={emailChangeHandler}
            validateOnFocusOut
            value={props.enteredEmail}
          />
          <TextField
            required
            inputClassName="input-19"
            className="form-text-field"
            type="tel"
            label="Mobile Number"
            placeholder="Enter mobile number "
            onGetErrorMessage={getMobileNumberErrorMessage}
            errorMessage={
              mobileNumberErrormessage
                ? "Please enter a valid phone number."
                : ""
            }
            validateOnFocusOut
            onChange={mobileNumberChangeHandler}
            value={props.enteredMobileNumber}
          />
          <TextField
            required
            inputClassName="input-20"
            className="form-text-field"
            placeholder="Enter password"
            label="Password"
            type="password"
            canRevealPassword
            errorMessage={
              passwordErrormessage ? "Please enter a strong password." : ""
            }
            validateOnFocusOut
            revealPasswordAriaLabel="Show password"
            onGetErrorMessage={getPasswordErrorMessage}
            onChange={passwordChangeHandler}
            value={props.enteredPassword}
          />
          <TextField
            required
            inputClassName="input-21"
            className="form-text-field"
            label="Confirm Password"
            placeholder="Enter password"
            type="password"
            canRevealPassword
            errorMessage={
              cPasswordErrormessage ? "Please enter matching passwords." : ""
            }
            validateOnFocusOut
            revealPasswordAriaLabel="Show password"
            onGetErrorMessage={getCPasswordErrorMessage}
            onChange={cPasswordChangeHandler}
            value={enteredCPassword}
          />
        </form>

        <ActionButtons
          primarytext={isCrateAccountLoading ? null : "Create Account"}
          secondarytext={"Back"}
          onPrimaryButtonClickHandler={createAccountHandler}
          onDefaultButtonClickHandler={backHandler}
          element={
            isCrateAccountLoading ? (
              <Spinner className="btn-spinner" size={SpinnerSize.small} />
            ) : null
          }
        />
        <div className="home-content-btn">
          <div className="create-account-sign">
            <Free />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default CreateNewAccount;
