import globalStyleVariables from "../../Styles/Variables/GlobalStyleVariables";
import styled from "@emotion/styled";

export const Container = styled.div<{
  height: number;
  responsiveHeight: number;
}>(({ height, responsiveHeight }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  height:
    window.innerWidth > globalStyleVariables.responsive
      ? height
      : responsiveHeight + "px",
}));
