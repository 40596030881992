import React, { useCallback, Fragment } from "react";
import "./Lanes.css";
import { ComboBox, IComboBoxOption, TextField } from "@fluentui/react";
import { UsAndCanadaStateOptions } from "../../../utils/FormValidations";
import { ReactComponent as Trash } from "../../../assets/Delete.svg";
import { LaneDetail, LaneDetailErrormessage } from "../LaneDetails";

interface LaneProps {
  index: number;
  setIsLanesDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
  formErrormessages: LaneDetailErrormessage[];
  setFormErrormessages: React.Dispatch<
    React.SetStateAction<LaneDetailErrormessage[]>
  >;
  lanes: LaneDetail[];
  setLanes: React.Dispatch<React.SetStateAction<LaneDetail[]>>;
}
const Lane = (props: LaneProps) => {
  const getFromCityErrorMessage = (value: string) => {
    if (value !== "" && value.length < 2) {
      const errorMessage = [...props.formErrormessages];
      errorMessage[props.index].fromCity = true;
      props.setFormErrormessages(errorMessage);
      return "";
    }
  };

  const getToCityErrorMessage = (value: string) => {
    if (value !== "" && value.length < 2) {
      const errorMessage = [...props.formErrormessages];
      errorMessage[props.index].toCity = true;
      props.setFormErrormessages(errorMessage);
      return "";
    }
  };

  const handleFieldFormChange = useCallback(
    (event, newValue: string, index: number) => {
      if (!newValue || newValue.length <= 50) {
        const errorMessage: any = [...props.formErrormessages];
        const data: any = [...props.lanes];
        data[index][event.target.name] = newValue;
        errorMessage[index][event.target.name] = false;
        props.setFormErrormessages(errorMessage);
        props.setIsLanesDuplicate(false);
        props.setLanes(data || []);
      }
    },
    [props.lanes]
  );

  const fromStateChangeHandler = useCallback(
    (index: number, event, option?: IComboBoxOption) => {
      if (option) {
        const errorMessage = [...props.formErrormessages];
        const data: LaneDetail[] = [...props.lanes];
        data[index].fromState = option;
        errorMessage[index].fromState = false;
        props.setIsLanesDuplicate(false);
        props.setFormErrormessages(errorMessage);
        props.setLanes(data || []);
      }
    },
    [props.lanes]
  );

  const toStateChangeHandler = useCallback(
    (index: number, event, option?: IComboBoxOption) => {
      if (option) {
        const errorMessage = [...props.formErrormessages];
        const data = [...props.lanes];
        data[index].toState = option;
        errorMessage[index].toState = false;
        props.setIsLanesDuplicate(false);
        props.setFormErrormessages(errorMessage);
        props.setLanes(data || []);
      }
    },
    [props.lanes]
  );

  const deleteLaneHandler = (e: any, index: number) => {
    props.setIsLanesDuplicate(false);
    const data = [...props.lanes];
    const errorMessage = [...props.formErrormessages];
    if (index > 0) {
      data.splice(index, 1);
      errorMessage.splice(index, 1);
    }
    props.setLanes(data);
    props.setFormErrormessages(errorMessage);
  };

  return (
    <Fragment>
      <div className="lane-detail-box">
        <div className="lane-detail-box-header">
          {props.index > 0 && (
            <Trash onClick={(e: any) => deleteLaneHandler(e, props.index)} />
          )}
        </div>
        <div className="lane-detail-section">
          <TextField            
            className="lane-detail-field"
            placeholder="Enter city"
            inputClassName="city-text input-16"
            name="fromCity"
            label="Origin"
            errorMessage={
              props.formErrormessages[props.index]!.fromCity
                ? "Please enter a valid City."
                : ""
            }            
            validateOnFocusOut
            onGetErrorMessage={getFromCityErrorMessage}
            onChange={(e, newValue) =>
              handleFieldFormChange(e, newValue!, props.index)
            }
            value={props.lanes[props.index]!.fromCity}
          />
          <ComboBox
            required
            className="lane-detail-dropdown"
            placeholder="Enter state"
            label="State"
            errorMessage={
              props.formErrormessages[props.index].fromState
                ? "Please select a state."
                : ""
            }
            onChange={(e, newValue) =>
              fromStateChangeHandler(props.index, e, newValue)
            }
            selectedKey={props.lanes[props.index].fromState?.key}
            options={UsAndCanadaStateOptions}
          />
        </div>
        <div className="lane-detail-section">
          <TextField            
            className="lane-detail-field"
            placeholder="Enter city"
            inputClassName="city-text input-16"
            label="Destination"
            errorMessage={
              props.formErrormessages[props.index]!.toCity
                ? "Please enter a valid City."
                : ""
            }
            validateOnFocusOut
            onGetErrorMessage={getToCityErrorMessage}
            onChange={(e, newValue) =>
              handleFieldFormChange(e, newValue!, props.index)
            }
            name="toCity"
            value={props.lanes[props.index]!.toCity}
          />
          <ComboBox
            required
            className="lane-detail-dropdown"
            placeholder="Enter state"
            label="State"
            errorMessage={
              props.formErrormessages[props.index].toState
                ? "Please select a state."
                : ""
            }
            onChange={(e, newValue) =>
              toStateChangeHandler(props.index, e, newValue)
            }
            selectedKey={props.lanes[props.index].toState?.key}
            options={UsAndCanadaStateOptions}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Lane;
