import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import accountInfoReducer from "./reducers/accountInfo";
import authenticationInfoReducer from "./reducers/userAuthentication";

const precictAccountInfoReducer = persistReducer(
  {
    key: "root_accountInfoReducer",
    storage,
  },
  accountInfoReducer
);

const presictAuthenticationInfoReducer = persistReducer(
  {
    key: "root_authenticationInfoReducer",
    storage,
  },
  authenticationInfoReducer
);

export const store = configureStore({
  reducer: {
    accountInfo: precictAccountInfoReducer,
    authenticationInfo: presictAuthenticationInfoReducer,
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);

// Infer the `RootState`, `AppDispatch` and `AppThunkDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
