import validator from "validator";
import { IComboBoxOption, IDropdownOption } from "@fluentui/react";

export const validEmail = (email: string) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ? true
    : false;
};

export const validPhoneNumber = (number: string) => {
  return validator.isMobilePhone(number);
};

export const strongPassword = (password: string) => {
  return validator.isStrongPassword(password, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  });
};

export const isValidPostalCode = (postalCode: string, countryCode: string) => {

  //hack -> we will fix for next release.
  return postalCodeMeetsMinimumLength(postalCode.trim());

  // let postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
  // switch (countryCode) {
  //   case "US":
  //     postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
  //     break;
  //   case "CA":
  //     postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
  //     break;
  //   default:
  //     postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
  // }

  // return postalCodeRegex.test(postalCode.trim());
};

///This is a hack for the first release because we're short on time.
//Length of 5 or 6, 5 for the US or 7 for Canada
export const postalCodeMeetsMinimumLength = (postalCode: string) =>{
 return postalCode.length == 5 || postalCode.length == 7;
}

export const checkNumber = (strArg: string) => {
  if (strArg.trim() == "") {
    return false;
  }
  let countRegex = /^([0-9]{5}|[0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9})?$/;
  return countRegex.test(strArg);
};

export const validSCACAndDOTNumber = (nMCIDNumber: string) => {
  if (nMCIDNumber.trim() == "") {
    return false;
  }
  const dotRegex = /^([0-9]{6}|[0-9]{7}|[0-9]{8})?$/;
  return dotRegex.test(nMCIDNumber);
};

export const UsAndCanadaStateOptions: IComboBoxOption[] = [
  { key: "Alabama", text: "AL" },
  { key: "Alaska", text: "AK" },
  { key: "American Samoa", text: "AS" },
  { key: "Arizona", text: "AZ" },
  { key: "Arkansas", text: "AR" },
  { key: "California", text: "CA" },
  { key: "Colorado", text: "CO" },
  { key: "Connecticut", text: "CT" },
  { key: "Delaware", text: "DE" },
  { key: "District Of Columbia", text: "DC" },
  { key: "Federated States Of Micronesia", text: "FM" },
  { key: "Florida", text: "FL" },
  { key: "Georgia", text: "GA" },
  { key: "Guam", text: "GU" },
  { key: "Hawaii", text: "HI" },
  { key: "Idaho", text: "ID" },
  { key: "Illinois", text: "IL" },
  { key: "Indiana", text: "IN" },
  { key: "Iowa", text: "IA" },
  { key: "Kansas", text: "KS" },
  { key: "Kentucky", text: "KY" },
  { key: "Louisiana", text: "LA" },
  { key: "Maine", text: "ME" },
  { key: "Marshall Islands", text: "MH" },
  { key: "Maryland", text: "MD" },
  { key: "Massachusetts", text: "MA" },
  { key: "Michigan", text: "MI" },
  { key: "Minnesota", text: "MN" },
  { key: "Mississippi", text: "MS" },
  { key: "Missouri", text: "MO" },
  { key: "Montana", text: "MT" },
  { key: "Nebraska", text: "NE" },
  { key: "Nevada", text: "NV" },
  { key: "New Hampshire", text: "NH" },
  { key: "New Jersey", text: "NJ" },
  { key: "New Mexico", text: "NM" },
  { key: "New York", text: "NY" },
  { key: "North Carolina", text: "NC" },
  { key: "North Dakota", text: "ND" },
  { key: "Northern Mariana Islands", text: "MP" },
  { key: "Ohio", text: "OH" },
  { key: "Oklahoma", text: "OK" },
  { key: "Oregon", text: "OR" },
  { key: "Palau", text: "PW" },
  { key: "Pennsylvania", text: "PA" },
  { key: "Puerto Rico", text: "PR" },
  { key: "Rhode Island", text: "RI" },
  { key: "South Carolina", text: "SC" },
  { key: "South Dakota", text: "SD" },
  { key: "Tennessee", text: "TN" },
  { key: "Texas", text: "TX" },
  { key: "Utah", text: "UT" },
  { key: "Vermont", text: "VT" },
  { key: "Virgin Islands", text: "VI" },
  { key: "Virginia", text: "VA" },
  { key: "Washington", text: "WA" },
  { key: "West Virginia", text: "WV" },
  { key: "Wisconsin", text: "WI" },
  { key: "Wyoming", text: "WY" },
  { key: "Alberta", text: "AB"},
  { key: "British Columbia", text: "BC"},
  { key: "Manitoba", text: "MB"},
  { key: "New Brunswick", text: "NB"},
  { key: "Newfoundland and Labrado", text: "NL"},
  { key: "Northwest Territories", text:"NT"},
  { key: "Nova Scotia", text: "NS"},
  { key: "Nunavut", text: "NU"},
  { key: "Ontario", text: "ON"},
  { key: "Prince Edward Island", text: "PE"},
  { key: "Quebec", text: "QC"},
  { key: "Saskatchewan", text: "SK"},
  { key: "Yukon", text: "YT"}
];

export const isInt = (value: number) => {
  return (
    !isNaN(value) &&
    (function (x) {
      return x;
    })(BigInt(value))
  );
};
