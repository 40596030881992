export interface trailerType {
  [x: string]: {
    id: number;
    isChecked: boolean;
    name: string;
  }[];
}

export const trailerTypesList: trailerType = {
  ["Container" as string]: [
    { id: 3, isChecked: false, name: "Container Insulated" },
    { id: 4, isChecked: false, name: "Container Refrigerated" },
  ],
  ["Flatbed" as string]: [
    { id: 9, isChecked: false, name: "Flatbed" },
    { id: 10, isChecked: false, name: "Flatbed AirRide" },
    { id: 11, isChecked: false, name: "Flatbed BTrain" },
    { id: 12, isChecked: false, name: "Flatbed Conestoga" },
    { id: 13, isChecked: false, name: "Flatbed Double" },
    { id: 14, isChecked: false, name: "Flatbed HazMat" },
    { id: 15, isChecked: false, name: "Flatbed Hotshot" },
    { id: 16, isChecked: false, name: "Flatbed Maxi" },
    { id: 17, isChecked: false, name: "Flatbed or StepDeck" },
    { id: 18, isChecked: false, name: "Flatbed Over Dimension" },
    { id: 19, isChecked: false, name: "Flatbed VanReefer" },
    { id: 20, isChecked: false, name: "Flatbedw Chains" },
    { id: 21, isChecked: false, name: "Flatbed W Sides" },
    { id: 22, isChecked: false, name: "Flatbed W Tarps" },
    { id: 23, isChecked: false, name: "Flatbed W Team" },
  ],

  ["Lowboy" as string]: [
    { id: 26, isChecked: false, name: "Lowboy" },
    { id: 27, isChecked: false, name: "Lowboy or RGN" },
    { id: 28, isChecked: false, name: "Lowboy Over Dimension" },
  ],

  ["Reefer" as string]: [
    { id: 32, isChecked: false, name: "Reefer" },
    { id: 33, isChecked: false, name: "Reefer AirRide" },
    { id: 34, isChecked: false, name: "Reefer Double" },
    { id: 35, isChecked: false, name: "Reefer HazMat" },
    { id: 36, isChecked: false, name: "Reefer Intermodal" },
    { id: 37, isChecked: false, name: "Reefer Logistics" },
    { id: 38, isChecked: false, name: "Reefer or Vented Van" },
    { id: 39, isChecked: false, name: "Reefer W Pallet Exchange" },
    { id: 40, isChecked: false, name: "Reefer W Team" },
  ],

  ["Van" as string]: [
    { id: 51, isChecked: false, name: "Van" },
    { id: 52, isChecked: false, name: "Van AirRide" },
    { id: 53, isChecked: false, name: "Van Conestoga" },
    { id: 54, isChecked: false, name: "Van Curtain" },
    { id: 55, isChecked: false, name: "Van Double" },
    { id: 56, isChecked: false, name: "Van HazMat" },
    { id: 57, isChecked: false, name: "Van Hotshot" },
    { id: 58, isChecked: false, name: "Van Insulated" },
    { id: 59, isChecked: false, name: "Van Intermodal" },
    { id: 60, isChecked: false, name: "Van Lift Gate" },
    { id: 61, isChecked: false, name: "an Logistics" },
    { id: 62, isChecked: false, name: "Van Open Top" },
    { id: 63, isChecked: false, name: "Van or Flatbed W Tarps" },
    { id: 64, isChecked: false, name: "Van or Reefer" },
    { id: 65, isChecked: false, name: "Van Roller Bed" },
    { id: 66, isChecked: false, name: "Van Triple" },
    { id: 67, isChecked: false, name: "Van Vented" },
    { id: 68, isChecked: false, name: "Van W Blanket Wrap" },
    { id: 69, isChecked: false, name: "Van W Pallet Exchange" },
    { id: 70, isChecked: false, name: "Van W Team" },
  ],
  ["Other trailer types" as string]: [
    { id: 1, isChecked: false, name: "Auto Carrier" },
    { id: 2, isChecked: false, name: "Conestoga" },
    { id: 5, isChecked: false, name: "Conveyor" },
    { id: 6, isChecked: false, name: "Double Drop" },
    { id: 7, isChecked: false, name: "DropDeck Landoll" },
    { id: 8, isChecked: false, name: "Dump Trailer" },
    { id: 24, isChecked: false, name: "Hopper Bottom" },
    { id: 25, isChecked: false, name: "Insulated VanorReefer" },
    { id: 29, isChecked: false, name: "Moving Van" },
    { id: 30, isChecked: false, name: "Pneumatic" },
    { id: 31, isChecked: false, name: "Power Only" },
    { id: 41, isChecked: false, name: "Removable Gooseneck" },
    { id: 42, isChecked: false, name: "StepDeck" },
    { id: 43, isChecked: false, name: "Stepdeck Conestoga" },
    { id: 44, isChecked: false, name: "StepDeck or RGN" },
    { id: 45, isChecked: false, name: "Straight Box Truck" },
    { id: 46, isChecked: false, name: "Stretch Trailer" },
    { id: 47, isChecked: false, name: "Tanker Aluminum" },
    { id: 48, isChecked: false, name: "Tanker Intermodal" },
    { id: 49, isChecked: false, name: "Tanker Steel" },
    { id: 50, isChecked: false, name: "Truck and Trailer" },
  ],
};
