import React, { useState, useCallback } from "react";
import "../../Styles/UI/PrimeExpressTheme.css";
import apiClient from "../../api/http_client";
import "../BusinessDetails/BusinessDetails.css";
import ActionButtons from "../../components/buttons/ActionButtons";
import { isInt, validSCACAndDOTNumber } from "../../utils/FormValidations";
import { TextField } from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { addAccountInfo } from "../../store/reducers/accountInfo";

interface BusinessAuthorityProps {
  setEnteredInsCoverage: React.Dispatch<React.SetStateAction<string>>;
  setEnteredInsPolicy: React.Dispatch<React.SetStateAction<string>>;
  setEnteredInsCarrier: React.Dispatch<React.SetStateAction<string>>;
  setEnteredSCAC: React.Dispatch<React.SetStateAction<string>>;
  setEnteredDotNumber: React.Dispatch<React.SetStateAction<string>>;
  enteredInsCoverage: string;
  enteredInsPolicy: string;
  enteredInsCarrier: string;
  enteredSCAC: string;
  enteredDOTNumber: string;
  setPercentComplete: React.Dispatch<React.SetStateAction<number>>;
  setShowPage: React.Dispatch<React.SetStateAction<string>>;
}

const BusinessAuthority = (props: BusinessAuthorityProps) => {
  const dispatch = useDispatch();
  const storAccountInfo = useSelector(
    (state: RootState) => state.accountInfo.AccountInfo
  );

  const [DOTNumberErrormessage, setDOTNumberErrormessage] =
    useState<string>("");
  const [SCACErrormessage, setSCACErrormessage] = useState<boolean>(false);
  const [InsCarrierErrormessage, setInsCarrierErrormessage] =
    useState<boolean>(false);
  const [InsPolicyErrormessage, setInsPolicyErrormessage] =
    useState<boolean>(false);
  const [InsCoverageErrormessage, setInsCoverageErrormessage] =
    useState<string>("");
  //TODO Validate the dot number if exist on freightnet or not if exist return error
  function get_dot(dotNumber) {
    return apiClient.get("/dot_number?dot=" + dotNumber);
  }

  async function nextHandler() {
    if (!validSCACAndDOTNumber(props.enteredDOTNumber)) {
      setDOTNumberErrormessage("Please enter a valid DOT number.");
    }
    if (props.enteredSCAC === "" || props.enteredSCAC.length === 1) {
      setSCACErrormessage(true);
    }
    if (props.enteredInsCarrier === "") {
      setInsCarrierErrormessage(true);
    }
    if (props.enteredInsPolicy === "") {
      setInsPolicyErrormessage(true);
    }
    if (!isInt(parseInt(props.enteredInsCoverage))) {
      setInsCoverageErrormessage(
        "Please enter a valid insurance coverage amount"
      );
    }

    if (parseInt(props.enteredInsCoverage) < 750000) {
      setInsCoverageErrormessage(
        "Insurance coverage must be at least 750,000 dollars."
      );
    }
    if (
      validSCACAndDOTNumber(props.enteredDOTNumber) &&
      (props.enteredSCAC !== "" || props.enteredSCAC.length > 1) &&
      props.enteredInsCarrier !== "" &&
      props.enteredInsPolicy !== "" &&
      isInt(parseInt(props.enteredInsCoverage)) &&
      parseInt(props.enteredInsCoverage) >= 750000
    ) {
      const businessAuthorityInfo = {
        sCACNumber: props.enteredSCAC,
        dOTNumber: props.enteredDOTNumber,
        insCarrier: props.enteredInsCarrier,
        insPolicy: props.enteredInsPolicy,
        insCoverage: props.enteredInsCoverage,
      };
      dispatch(
        addAccountInfo({
          ...storAccountInfo,
          ...businessAuthorityInfo,
        })
      );
      props.setShowPage("fleet");
      props.setPercentComplete(0.6);
    }
  }

  const previousHandler = () => {
    props.setPercentComplete(0.2);
    props.setShowPage("business");
  };

  const SCACChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 4) {
        props.setEnteredSCAC(newValue || "");
        setSCACErrormessage(false);
      }
    },
    [props.enteredSCAC]
  );

  const DotNumberChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 8) {
        props.setEnteredDotNumber(newValue || "");
        setDOTNumberErrormessage("");
      }
    },
    [props.enteredDOTNumber]
  );

  const InsCarrierChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredInsCarrier(newValue || "");
        setInsCarrierErrormessage(false);
      }
    },
    [props.enteredInsCarrier]
  );

  const InsPolicyChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 50) {
        props.setEnteredInsPolicy(newValue || "");
        setInsPolicyErrormessage(false);
      }
    },
    [props.enteredInsPolicy]
  );

  const InsCoverageChangeHandler = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 20) {
        props.setEnteredInsCoverage(newValue || "");
        setInsCoverageErrormessage("");
      }
    },
    [props.enteredInsCoverage]
  );

  const getSCACErrorMessage = (value: string) => {
    if (value !== "") {
      value.length === 1 && setSCACErrormessage(true);
      return "";
    }
  };

  const getDotNumberErrorMessage = (value: string) => {
    if (value !== "") {
      if (!isInt(parseInt(value)) || value.length < 5) {
        setDOTNumberErrormessage("Please enter a valid DOT number.");
      }
      return "";
    }
  };

  const getInsCarrierErrorMessage = (value: string) => {
    if (value !== "") {
      value.length < 3 && setInsCarrierErrormessage(true);
      return "";
    }
  };

  const getInsPolicyErrorMessage = (value: string) => {
    if (value !== "") {
      value.length < 2 && setInsPolicyErrormessage(true);
      return "";
    }
  };

  const getInsCoverageErrorMessage = (value: string) => {
    if (value !== "") {
      if (!isInt(parseInt(value))) {
        setInsCoverageErrormessage(
          "Please enter a valid insurance coverage amount"
        );
      } else if (parseInt(value) < 750000) {
        setInsCoverageErrormessage(
          "Insurance coverage must be at least 750,000 dollars."
        );
      }
      return "";
    }
  };

  return (
    <div className="business-details-middle-section">
      <h1>Provide your authority </h1>

      <form className="business-details-light-gray-box">
        <div className="gray-box-header">
          <p>FMCSA information</p>
        </div>
        <TextField
          inputClassName="input-3"
          className="form-text-field"
          placeholder="Enter DOT "
          label="DOT number"
          required
          onGetErrorMessage={getDotNumberErrorMessage}
          errorMessage={DOTNumberErrormessage}
          validateOnFocusOut
          onChange={DotNumberChangeHandler}
          value={props.enteredDOTNumber}
        />

        <TextField
          inputClassName="input-4"
          className="form-text-field"
          placeholder="Enter SCAC "
          label="SCAC code"
          required
          onGetErrorMessage={getSCACErrorMessage}
          errorMessage={
            SCACErrormessage ? "Please enter a valid SCAC number." : ""
          }
          validateOnFocusOut
          onChange={SCACChangeHandler}
          value={props.enteredSCAC}
        />
        <div className="gray-box-header">
          <p>Insurance information</p>
        </div>
        <TextField
          inputClassName="input-5"
          className="form-text-field"
          placeholder="Enter insurance carrier "
          label="Insurance Carrier"
          required
          onGetErrorMessage={getInsCarrierErrorMessage}
          errorMessage={
            InsCarrierErrormessage
              ? "Please enter a valid insurance carrier."
              : ""
          }
          validateOnFocusOut
          onChange={InsCarrierChangeHandler}
          value={props.enteredInsCarrier}
        />

        <TextField
          inputClassName="input-6"
          className="form-text-field"
          placeholder="Enter policy number "
          label="Policy Number"
          required
          onGetErrorMessage={getInsPolicyErrorMessage}
          errorMessage={
            InsPolicyErrormessage ? "Please enter a valid policy number." : ""
          }
          validateOnFocusOut
          onChange={InsPolicyChangeHandler}
          value={props.enteredInsPolicy}
        />
        <TextField
          inputClassName="input-7"
          className="form-text-field"
          placeholder="Enter coverage Amount "
          label="Coverage Amount"
          required
          onGetErrorMessage={getInsCoverageErrorMessage}
          errorMessage={InsCoverageErrormessage}
          validateOnFocusOut
          onChange={InsCoverageChangeHandler}
          value={props.enteredInsCoverage}
        />
      </form>

      <div className="business-details-footer">
        <ActionButtons
          primarytext={"Next"}
          secondarytext={"Previous"}
          onPrimaryButtonClickHandler={nextHandler}
          onDefaultButtonClickHandler={previousHandler}
        />
      </div>
    </div>
  );
};

export default BusinessAuthority;
